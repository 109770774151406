import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      userLoggedIn: 'user/loggedIn'
    }),

    beautyPassLinks() {
      const links = [
        {
          label: this.$t('layout.navBar.beautyPassBenefits'),
          path: '/beauty-pass'
        },
        {
          label: this.$t('layout.navBar.rewardsBoutique'),
          path: '/rewards-boutique'
        }
      ]
      if (this.userLoggedIn) {
        links.push({
          label: this.$t('layout.navBar.pointsSummary'),
          path: '/account/point_logs'
        })
      }
      return links
    }
  }
}
