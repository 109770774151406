export default class FeatureCarouselTransformer {
  constructor(carousel, items) {
    this.featureCarousel = carousel
    this.featureCarouselItems = items
  }

  get transformedData() {
    return {
      id: this.featureCarousel.id,
      title: this.featureCarousel.attributes.title,
      items: this.featureCarouselItems.map((item) => ({
        id: item.id,
        title: item.attributes.title,
        subTitle: item.attributes.description,
        position: item.attributes.position,
        ctaLabel: item.attributes['cta-label'],
        bannerUrl: item.attributes.url,
        imgSrc: item.attributes['image-url']
      }))
    }
  }
}
