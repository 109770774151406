import ProductRecommendationsTransformer from '../api/ProductRecommendationsTransformer.js'

export const state = () => ({
  recommendations: []
})

export const mutations = {
  setRecommendations(state, data) {
    Object.assign(state, data)
  }
}

export const getters = {
  recommendations(state) {
    return state.recommendations || []
  },
  recommendationId(state) {
    return state.recommendationId || ''
  },
  strategy(state) {
    return state.strategy || ''
  },
  splitKey(state) {
    return state.splitKey || ''
  }
}

export const actions = {
  async fetchProductRecommendations(
    { commit },
    { path, recommendationsCount }
  ) {
    try {
      commit('setRecommendations', {
        recommendations: []
      })

      const payload = {
        recently_viewed_products:
          JSON.parse(localStorage.getItem('PRODUCT_VIEWS')) || [],
        page_path: path,
        n_recommendations: recommendationsCount
      }
      const data = await this.$repositories.productRecommendations.post(payload)

      if (data.recommendations?.length) {
        commit(
          'setRecommendations',
          new ProductRecommendationsTransformer(data).transformedData
        )
      }
    } catch {}
  }
}
