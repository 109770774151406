import Vue from 'vue'

function showNotification(options) {
  Vue.notify({
    duration: 5000,
    text: options.text,
    type: options.type || 'info'
  })
}

export { showNotification }
