<template>
  <SmartLink :native="false" to="/sign_in">
    <TopNavigationElement
      :text="$t('dictionary.register')"
      :additional-attrs="additionalAttrs"
    />
  </SmartLink>
</template>

<script>
import TopNavigationElement from '@sephora-asia/core-top-navigation-element/TopNavigationElement.vue'

export default {
  name: 'TopNavigationSignUp',

  components: {
    TopNavigationElement
  },

  computed: {
    additionalAttrs() {
      return {
        'data-analytics-id': 'header-click',
        'data-analytics-label': 'sign_in'
      }
    }
  }
}
</script>
