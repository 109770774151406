<template>
  <div v-if="hasOnlineReservationSystem" class="stores-and-services">
    <Dropdown
      v-if="hasStoreLocations"
      class="stores-and-events-dropdown is-hidden-tablet-only"
      @click.prevent
    >
      <TopNavigationElement
        :text="$t('layout.shared.storesAndEvents')"
        :icon-src="$options.locals.storeIcon"
        @click="fetchResources"
      />
      <template slot="content">
        <Loader
          v-show="!isBookingServiceLoaded"
          :loading="!isBookingServiceLoaded"
          class="loader-container"
        />
        <SmartLink
          v-for="link in storesAndEventsLinks"
          v-show="isBookingServiceLoaded"
          :key="link.label"
          :native="false"
          :to="link.path"
          class="dropdown-item"
        >
          {{ link.label }}
          <span class="dropdown-item-description" v-text="link.description" />
        </SmartLink>
      </template>
    </Dropdown>
    <Dropdown class="beauty-services-dropdown is-hidden-tablet-only">
      <TopNavigationElement
        :text="$t('layout.shared.beautyServices')"
        :icon-src="$options.locals.beautyServicesIcon"
        @click="fetchResources"
      />
      <template slot="content">
        <Loader
          v-show="!isBookingServiceLoaded"
          :loading="!isBookingServiceLoaded"
          class="loader-container"
        />
        <SmartLink
          v-for="link in beautyServicesLinks"
          v-show="isBookingServiceLoaded"
          :key="link.label"
          :native="false"
          :to="link.path"
          class="dropdown-item"
          v-text="link.label"
        />
      </template>
    </Dropdown>
    <Dropdown class="stores-and-services-dropdown is-hidden-desktop">
      <TopNavigationElement
        :text="$t('layout.shared.storesAndServices')"
        :icon-src="$options.locals.storeIcon"
        @click="fetchResources"
      />
      <template slot="content">
        <Loader
          v-if="!isBookingServiceLoaded"
          :loading="!isBookingServiceLoaded"
          class="loader-container"
        />
        <template v-else>
          <div class="sub-title dropdown-item">
            {{ $t('layout.shared.beautyServices') }}
          </div>
          <SmartLink
            v-for="link in beautyServicesLinks"
            :key="link.label"
            :native="false"
            :to="link.path"
            class="dropdown-item"
            v-text="link.label"
          />
          <template v-if="hasStoreLocations">
            <hr class="dropdown-separator" />
            <div class="sub-title dropdown-item">
              {{ $t('layout.shared.storesAndEvents') }}
            </div>
            <SmartLink
              v-for="link in storesAndEventsLinks"
              :key="link.label"
              :native="false"
              :to="link.path"
              class="dropdown-item"
            >
              {{ link.label }}
              <span
                class="dropdown-item-description"
                v-text="link.description"
              />
            </SmartLink>
          </template>
        </template>
      </template>
    </Dropdown>
  </div>
  <SmartLink
    v-else-if="hasStoreLocations"
    :native="false"
    :to="storeLocationsLink.path"
    class="stores-and-services"
  >
    <TopNavigationElement
      :text="storeLocationsLink.label"
      :icon-src="$options.locals.storeIcon"
    />
  </SmartLink>
</template>

<script>
import Dropdown from '@sephora-asia/core-dropdown/Dropdown.vue'
import Loader from '@sephora-asia/core-loader/Loader.vue'
import TopNavigationElement from '@sephora-asia/core-top-navigation-element/TopNavigationElement.vue'
import { mapGetters } from 'vuex'
import bookingServiceMixin from '~/mixins/bookingServiceMixin'
import StoreIcon from '~/assets/images/store-location.svg'
import BeautyServicesIcon from '~/assets/images/beauty-services-calendar.svg'

export default {
  name: 'TopNavigationStoresServices',
  components: {
    Dropdown,
    Loader,
    TopNavigationElement
  },
  mixins: [bookingServiceMixin],

  computed: {
    ...mapGetters({
      hasStoreLocations: 'globalConfig/hasStoreLocations',
      hasOnlineReservationSystem: 'globalConfig/hasOnlineReservationSystem'
    })
  },

  methods: {
    async fetchResources() {
      if (!this.isBookingServiceLoaded && this.hasOnlineReservationSystem) {
        await this.$store.dispatch('bookingService/fetchAll')
      }
    }
  },

  locals: {
    storeIcon: StoreIcon,
    beautyServicesIcon: BeautyServicesIcon
  }
}
</script>

<style lang="scss" scoped>
.loader-container {
  padding-top: 15px;
  padding-bottom: 15px;
}
.stores-and-services {
  ::v-deep .dropdown-content {
    max-width: 240px;
  }

  ::v-deep .dropdown-item {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sub-title {
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: -5px;
  }

  .sub-title:not(:first-child) {
    margin-top: 10px;
  }

  .dropdown-separator {
    margin: 1rem;
  }

  .dropdown-item-description {
    color: $grey-darker;
    display: block;
    font-size: 10px;
  }
}

.stores-and-services,
.stores-and-events-dropdown {
  margin-right: 20px;
}
</style>
