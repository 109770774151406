import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

export default function ({ app, env }, inject) {
  if (app.$env.BUGSNAG_CLIENT_API_KEY) {
    const bugsnagClient = Bugsnag.start({
      apiKey: app.$env.BUGSNAG_CLIENT_API_KEY,
      appVersion: env.appVersion,
      plugins: [new BugsnagPluginVue(Vue)],
      releaseStage: app.$env.BUGSNAG_ENV,
      enabledReleaseStages: ['production', 'staging']
    })
    inject('bugsnag', bugsnagClient)
  }
}
