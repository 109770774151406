export default class CategoryTransformer {
  constructor(categoryData, included = []) {
    this.category = categoryData
    this.sidebarContent = included.find(
      (obj) => obj.type === 'sidebar-contents'
    )
  }

  get transformedData() {
    return {
      id: this.category.id,
      label: this.category.attributes.label,
      slugUrl: this.category.attributes['slug-url'],
      topBanner: {
        imageUrl: this.category.attributes['top-banners']['top-banner2'],
        altText:
          this.category.attributes['top-banners-details'][
            'top-banner2-alt-text'
          ]
      },
      seo: {
        pageTitle: this.category.attributes.seo['page-title'],
        description: this.category.attributes.seo['meta-description']
      },
      footerContent: this.sidebarContent?.attributes?.content || null
    }
  }
}
