<template>
  <ContentCard style="height: 460px" data-analytics-id="empty-cart">
    <img
      v-lazy-load
      :data-src="$options.locals.emptyCartIcon"
      class="empty-cart-image"
    />
    <p class="empty-cart-message">
      {{ $t('layout.cart.emptyCartMessage') }}
    </p>
    <p class="empty-cart-suggestion">
      {{ $t('layout.cart.emptyCartSuggestion') }}
    </p>
    <Button
      url="/categories/makeup"
      :label="$t('layout.cart.emptyCartButtonText')"
      type="btn-default"
      data-analytics-id="empty-cart-button"
    />
  </ContentCard>
</template>

<script>
import Button from '@sephora-asia/core-button/Button.vue'
import ContentCard from '@sephora-asia/core-content-card/ContentCard.vue'
import EmptyCartIcon from '~/assets/images/empty-cart-icon.svg'

export default {
  name: 'TopNavigationDropdownCartEmpty',
  components: {
    Button,
    ContentCard
  },
  locals: {
    emptyCartIcon: EmptyCartIcon
  }
}
</script>

<style lang="scss" scoped>
::v-deep .content-card {
  height: 90%;
}
.empty-cart-image {
  margin-bottom: 10px;
  height: 40px;
  width: 40px;
}
.empty-cart-message,
.empty-cart-suggestion {
  line-height: 3em;
}
.empty-cart-suggestion {
  margin-bottom: 20px;
}
</style>
