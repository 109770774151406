export default class AnalyticsInterface {
  constructor(analyticModulesEnabled) {
    this.analyticModulesEnabled = analyticModulesEnabled
  }

  trackEvent(eventName, params = {}, excludedModules = []) {
    this.analyticModulesEnabled.forEach((module) => {
      if (!excludedModules.includes(module.name)) {
        module.trackEvent(eventName, params)
      }
    })
  }
}
