<template>
  <SmartLink :native="false" to="/wishlist" class="wishlist">
    <TopNavigationElement
      :text="$t('dictionary.wishlist')"
      :icon-src="$options.locals.heartIcon"
      :additional-attrs="additionalAttrs"
      :hide-text-on-tablet="true"
      @click="preventDefault()"
    />
  </SmartLink>
</template>

<script>
import TopNavigationElement from '@sephora-asia/core-top-navigation-element/TopNavigationElement.vue'
import HeartIcon from '~/assets/images/heart-white.svg'

export default {
  name: 'TopNavigationWishlist',

  components: {
    TopNavigationElement
  },

  locals: {
    heartIcon: HeartIcon
  },

  computed: {
    additionalAttrs() {
      return {
        'data-analytics-id': 'header-click',
        'data-analytics-label': 'wishlist'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wishlist {
  margin-right: 20px;

  @include until-widescreen {
    margin-right: 15px;
  }
}
</style>
