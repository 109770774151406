<template functional>
  <div class="content">
    <div class="title">
      {{ props.title }}
    </div>

    <SmartLink
      v-for="(item, index) in props.list"
      :key="index"
      :native="false"
      :to="item.path"
      class="item"
    >
      {{ item.text }}
    </SmartLink>
  </div>
</template>

<script>
export default {
  name: 'FooterList',
  props: {
    title: {
      type: String,
      required: true
    },
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: $black;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.item {
  display: block;
  line-height: 1.75em;
}
</style>
