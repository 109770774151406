import { defaultLanguageCode, isLanguageAvailable } from './i18n.js'

function canonicalizeUrl(url, countryCode, opts) {
  const urlObject = new URL(url)

  const languageQuery = urlObject.searchParams.get('language')
  const pageQuery = urlObject.searchParams.get('page')

  // remove all the params
  urlObject.search = ''

  // retain only language and page query for canonical
  if (languageQuery && canAttachLanguage(languageQuery, countryCode)) {
    urlObject.searchParams.append('language', languageQuery)
  }
  // only show page query for brands non sub-category listing page
  if (pageQuery && opts.showPageQuery) {
    urlObject.searchParams.append('page', pageQuery)
  }

  return urlObject.toString()
}

function canAttachLanguage(languageCode, countryCode) {
  return (
    isLanguageAvailable(countryCode, languageCode) &&
    defaultLanguageCode(countryCode) !== languageCode
  )
}

export default canonicalizeUrl
