import Vue from 'vue'
import Analytics from '../../modules/analytics/analytics.js'

const gtmConfig = {
  id: 'GTM-N72VQC',
  queryParams: {},
  defer: false,
  async: true
}

const analyticsClient = new Analytics(Vue, {
  modules: {
    gtm: gtmConfig
  }
})

export default function ({ app, beforeNuxtRender, nuxtState }, inject) {
  if (process.server) {
    beforeNuxtRender(({ nuxtState }) => {
      nuxtState.disableGTM = !!app.$cookies.get('disable-gtm')
    })
  } else if (!nuxtState.disableGTM && analyticsClient) {
    inject('analytics', analyticsClient.install())
  }
}
