<template>
  <Dropdown class="is-right" :activate="isActive" @close="closeDropdown">
    <template #default>
      <Badge
        :content="lineItemCount.toString()"
        :aria-label="
          $t('layout.cart.itemsCountAriaLabel', {}, { count: lineItemCount })
        "
        :hidden="lineItemCount === 0"
        vertical-position="bottom"
        offset-y="3px"
        size="compact"
        @click.native="$emit('bag-icon-click')"
      >
        <TopNavigationElement
          :text="$t('layout.cart.cartHeader')"
          :icon-src="$options.locals.cartIcon"
          :additional-attrs="additionalAttrs"
          :hide-text-on-tablet="true"
        />
      </Badge>
    </template>
    <template #content="{ close }">
      <div class="cart-dropdown">
        <Cart :header-label="$t('layout.cart.cartHeader')">
          <template slot="close-button">
            <Button
              :id="showAtbTrigger && 'dy-banner-atbd1-button-2'"
              class="close"
              label="✕"
              type="btn-clear"
              @click.prevent="
                {
                  {
                    close()
                  }
                }
              "
            />
          </template>
          <div slot="content" class="cart-dropdown-content">
            <component
              :is="activeContentComponent"
              :user-logged-in="userLoggedIn"
              :shipping-offers="shippingOffers"
              :loyalty-points="loyaltyPoints"
              :total-text="totalText"
              :line-items="lineItems"
              :loading="loading"
              :show-atb-trigger="showAtbTrigger"
              @remove-line-item="removeLineItem"
            />
          </div>
        </Cart>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import Dropdown from '@sephora-asia/core-dropdown/Dropdown.vue'
import TopNavigationElement from '@sephora-asia/core-top-navigation-element/TopNavigationElement.vue'
import Badge from '@sephora-asia/core-badge/Badge.vue'
import Cart from '@sephora-asia/core-cart/Cart.vue'
import Button from '@sephora-asia/core-button/Button.vue'
import TopNavigationDropdownCartSummary from './TopNavigationDropdownCartSummary.vue'
import TopNavigationDropdownCartEmpty from './TopNavigationDropdownCartEmpty.vue'
import CartIcon from '~/assets/images/cart-icon.svg'

export default {
  name: 'TopNavigationDropdownCart',
  components: {
    Badge,
    Button,
    Cart,
    Dropdown,
    TopNavigationElement,
    TopNavigationDropdownCartSummary,
    TopNavigationDropdownCartEmpty
  },

  props: {
    userLoggedIn: {
      type: Boolean,
      required: true
    },
    loyaltyPoints: {
      type: Number,
      default: 0
    },
    totalText: {
      type: String,
      default: ''
    },
    lineItems: {
      type: Array,
      required: true
    },
    lineItemCount: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    shippingOffers: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isActive: false
    }
  },

  computed: {
    activeContentComponent() {
      if (this.lineItemCount === 0) {
        return 'TopNavigationDropdownCartEmpty'
      } else {
        return 'TopNavigationDropdownCartSummary'
      }
    },
    additionalAttrs() {
      return {
        'data-analytics-id': 'header-click',
        'data-analytics-label': 'cart'
      }
    },
    showAtbTrigger() {
      return (
        !this.userLoggedIn &&
        this.$i18n.countryCode !== 'KR' &&
        this.lineItemCount > 0
      )
    }
  },

  mounted() {
    this.$nuxt.$on('open-cart-dropdown', this.openDropdown)
  },

  beforeDestroy() {
    this.$nuxt.$off('open-cart-dropdown', this.openDropdown)
  },

  locals: {
    cartIcon: CartIcon
  },

  methods: {
    removeLineItem(id) {
      this.$emit('remove-line-item', id)
    },
    closeDropdown() {
      this.isActive = false
    },
    openDropdown() {
      this.isActive = true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .dropdown-trigger > .badge-container {
  display: block;
}
::v-deep .dropdown-content {
  padding: 0;
}
::v-deep .dropdown-menu {
  margin-right: 20px;
}
.cart-dropdown {
  width: 360px;
  height: 100%;
}
</style>
