export default {
  methods: {
    getFormattedPath(url) {
      // converts URL from `/this/is-a-link/to/somewhere` to `this > is-a-link > to > somewhere
      // split changes the string into array (without `/`)
      // filter returns the array without the empty strings
      // remove `categories` if it is the first element
      // join changes the array into string with ` > ` in between element
      url = url.split('/').filter((el) => {
        return el
      })
      if (url[0] === 'categories') {
        url.shift()
      }

      return url.join(' > ')
    },

    trackTopMenuHover(eventName, path) {
      if (path) {
        this.$analytics.trackEvent(eventName, { params: { event_label: path } })
      }
    }
  }
}
