import Vue from 'vue'
import categoriesDataMixin from '~/mixins/categoriesDataMixin'
import menuItemFormatterMixin from '~/mixins/menuItemFormatterMixin.js'

const BRANDS_LINK = '/brands'
const CLEAN_AT_SEPHORA_LINK = '/pages/clean-at-sephora'
const CLEAN_CATEGORY_LINK = '/categories/clean'
const NEW_ARRIVALS_LINK = '/new-arrivals'
const SALE_LINK = '/sale'
const WELLNESS_LINK = '/pages/wellness'

const SidebarNavMenuItemsDataTransformer = (component) => {
  return Vue.component('SidebarNavMenuItemsDataTransformer', {
    mixins: [categoriesDataMixin, menuItemFormatterMixin],

    computed: {
      menuItemData() {
        const formattedCategories = this.convertCategories(
          this.categories,
          this.convertToMobileCategoryFormat
        )
        const menuItemTree = this.arrangeCategories(
          this.categories,
          formattedCategories
        )

        // Add view all <category>
        this.categories.forEach((category, _) => {
          if (
            !category.parentId ||
            formattedCategories[category.id].children.length > 0
          ) {
            const text = this.$t('layout.navBar.viewAll', {
              text: category.text
            })
            const convertedMobileCategoryFormat = this.convertToMobileCategoryFormat(
              text,
              category.link,
              [],
              {
                additionalAttrs: this.additionalAttrs(category, true)
              }
            )
            convertedMobileCategoryFormat.isNonNativeLink = false
            formattedCategories[category.id].children.unshift(
              convertedMobileCategoryFormat
            )
          }
        })

        menuItemTree.unshift(
          this.convertToMenuItemFormat(
            this.$t('layout.navBar.brands'),
            BRANDS_LINK,
            {
              additionalAttrs: this.formattedAdditionalAttrs('brands > Brands')
            }
          )
        )
        menuItemTree.unshift(
          this.convertToMenuItemFormat(
            this.$t('layout.navBar.new'),
            NEW_ARRIVALS_LINK,
            {
              additionalAttrs: this.formattedAdditionalAttrs(
                'new-arrivals > New'
              )
            }
          )
        )
        menuItemTree.push(
          this.convertToMenuItemFormat(
            this.$t('layout.navBar.sale'),
            SALE_LINK,
            {
              additionalAttrs: this.formattedAdditionalAttrs('sale > Sale')
            }
          )
        )

        menuItemTree.find((category) => {
          if (category.url === CLEAN_CATEGORY_LINK) {
            category.children.unshift(
              this.convertToMenuItemFormat(
                this.$t('layout.navBar.cleanAtSephora'),
                CLEAN_AT_SEPHORA_LINK,
                {
                  additionalAttrs: this.formattedAdditionalAttrs(
                    'clean > CLEAN-AT-SEPHORA'
                  )
                }
              )
            )
          }
        })

        // Add wellness data for AU
        if (this.$i18n.countryCode === 'AU') {
          menuItemTree.splice(
            -2,
            0,
            this.convertToMenuItemFormat(
              this.$t('layout.navBar.wellness'),
              WELLNESS_LINK,
              {
                additionalAttrs: this.formattedAdditionalAttrs(
                  'pages > wellness > Wellness'
                )
              }
            )
          )
        }

        return menuItemTree
      }
    },

    methods: {
      convertToMobileCategoryFormat(text, url, children = [], options = {}) {
        return this.convertToMenuItemFormat(text, url, {
          children,
          additionalAttrs: options.additionalAttrs,
          hasSeparator: true,
          urlPrefix: '/categories/'
        })
      }
    },

    render(createElement, _) {
      return createElement(component, {
        props: {
          menuItemData: this.menuItemData
        },
        on: {
          'toggle-sidebar-nav': () => {
            this.$emit('toggle-sidebar-nav')
          }
        }
      })
    }
  })
}

export default SidebarNavMenuItemsDataTransformer
