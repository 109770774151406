import set from 'lodash/set'
import merge from 'lodash/merge'

const processLocaleModule = (context, locales, countryCode) => {
  let countryMessages = {
    // <locale>: {...}
  }

  // context.keys should return country specific locales in alphabetical order
  context.keys().map((filePath) => {
    const lang = filePath.replace(/^\.\//, '').replace(/\.\w+$/, '')
    const val = context(filePath)

    return {
      lang,
      val
    }
  }).forEach((obj) => {
    const langSplit = obj.lang.split('_')
    const lang = langSplit[0]
    const countryLang = langSplit[1] || ''
    const val = obj.val

    if (!locales.includes(lang)) {
      return
    }

    // skip merging if the locale is not the base
    // skip merging if the locale with country is not same as the current country code
    if (countryLang.length > 0 && countryLang.toLowerCase() !== countryCode.toLowerCase()) {
      return
    }

    const tempCountryMessages = { ...countryMessages }

    // Setting the language key in the countryMessage object if language key undefined
    set(tempCountryMessages, `${lang}`, val)

    // This is to allow country level overwrite
    countryMessages = merge(
      {},
      countryMessages,
      tempCountryMessages
    )
  })

  return countryMessages
}

const mergeLocaleMessages = (i18n, context) => {
  const countryCode = i18n.countryCode
  const locales = i18n.languages
  const msgs = processLocaleModule(context, locales, countryCode)

  for (const locale in msgs) {
    i18n.mergeLocaleMessage(locale, msgs[locale])
  }
}

// This is for test (specs)
export {
  processLocaleModule,
  mergeLocaleMessages
}

export default ({ app }, inject) => {
  inject('mergeLocalesToI18n', mergeLocaleMessages)
}
