// TODO: This file should be removed once we move all locales into each modules
import set from 'lodash/set'
import merge from 'lodash/merge'

const processLocaleModule = (context) => {
  const messages = {}
  const localesModule = context.keys().map((filePath) => {
    const lang = filePath.replace(/^\.\//, '').replace(/\.\w+$/, '')
    const val = context(filePath)

    return {
      lang,
      val
    }
  })

  localesModule.forEach((obj) => {
    const langSplit = obj.lang.split('_')
    const lang = `${langSplit[0]}${langSplit[1]?.toUpperCase() || ''}`
    const val = obj.val
    const isCountrySpecific = obj.lang.includes('_')
    const msgKey = isCountrySpecific
      ? 'countrySpecificMessages'
      : 'sharedMessages'

    set(messages, `${msgKey}[${lang}]`, val)
  })

  return messages
}

// require.context MUST be statically analyzable. Only literals are supported.: https://github.com/webpack/webpack/issues/4772#issuecomment-358573955
const context = require.context('../locales', false, /\.json$/)
const messages = processLocaleModule(context)

export function getLocaleMessagesFor(locales = [], countryCode) {
  const countryMessages = {}

  locales.forEach((locale) => {
    // We use lodash's merge function here so that it only overwrites the correct key
    // Using JS spread operator will overwrite the entire object if the top level key is the same
    countryMessages[locale] = merge(
      {},
      messages.sharedMessages[locale],
      messages.countrySpecificMessages[`${locale}${countryCode.toUpperCase()}`]
    )
  })

  return countryMessages
}
