import isEmpty from 'lodash/isEmpty'
import AnalyticsInterface from './analyticsInterface.js'
import { analyticTypes } from './analyticTypes.js'

export default class Analytics {
  constructor(Vue, initConfig = {}) {
    this.vue = Vue
    this.initConfig = initConfig
  }

  install() {
    // break the initialisation when there's no config available
    if (isEmpty(this.initConfig.modules)) {
      return
    }

    this.vue.analyticModulesEnabled = []

    this.initialiseModules()

    // link the initialised modules with functions available
    return new AnalyticsInterface(this.vue.analyticModulesEnabled)
  }

  initialiseModules() {
    // loop through each analytic types and initialise the module
    for (const key in analyticTypes) {
      let module

      if (this.initConfig.modules[key]) {
        module = new analyticTypes[key](key.toString())
        module.init(this.initConfig.modules[key])
      }

      // push the initialised modules into array
      if (module) {
        this.vue.analyticModulesEnabled.push(module)
      }
    }
  }
}
