import OverlayTransformer from '~/api/OverlayTransformer'

export const state = () => ({
  overlay: {}
})

export const getters = {
  overlay(state) {
    return state.overlay
  }
}

export const mutations = {
  setOverlay(state, overlay) {
    state.overlay = overlay
  }
}

export const actions = {
  async fetchOverlay({ commit }, path) {
    try {
      const { data } = await this.$repositories.overlay.show({
        path
      })

      if (data) {
        await commit('setOverlay', new OverlayTransformer(data).transformedData)
      }
    } catch {}
  }
}
