import listingPageBrand from './modules/listingPageBrand'
import listingPageCategory from './modules/listingPageCategory'
import listingPageFilters from './modules/listingPageFilters'
import listingPageProducts from './modules/listingPageProducts'
import listingPageSearch from './modules/listingPageSearch'
import listingPageBrandRecommendations from './modules/listingPageBrandRecommendations'
// get the options out using lodash templates
const options = JSON.parse(`{"namespace":"listingPagesUi"}`)

const modules = {
  listingPageBrand,
  listingPageCategory,
  listingPageFilters,
  listingPageProducts,
  listingPageSearch,
  listingPageBrandRecommendations
}

export default ({ store }, inject) => {
  for (const moduleName in modules) {
    store.registerModule(moduleName, modules[moduleName](options), {
      preserveState: Boolean(store.state[moduleName]) // if the store module already exists, preserve it
    })
  }
}
