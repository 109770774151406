import { MOBILE_WEB, WEB } from '@sephora-asia/common-ui/config/platforms.js'
import QuickLinksTransformer from '../../api/QuickLinksTransformer.js'

export default () => ({
  namespaced: true,

  state: () => ({
    quickLinks: []
  }),

  mutations: {
    setQuickLinks(state, quickLinks) {
      state.quickLinks = quickLinks
    }
  },

  getters: {
    quickLinks(state) {
      return state.quickLinks
    }
  },

  actions: {
    async fetchQuickLinks({ commit }) {
      const platform = this.$device.isMobile ? MOBILE_WEB : WEB
      const { data } = await this.$repositories.quickLinks.index({
        'filter[platform]': platform,
        'page[size]': 6
      })

      if (data) {
        commit('setQuickLinks', new QuickLinksTransformer(data).transformedData)
      }
    }
  }
})
