import BrandBillboardsTransformer from './BrandBillboardsTransformer.js'
import ProductsTransformer from './ProductsTransformer.js'

export default class BrandTransformer {
  constructor(brand, included) {
    this.brand = brand
    this.included = included
    this.billboards = included?.filter((record) => record.type === 'billboards')
    this.products = included?.filter((record) => record.type === 'products')
    this.bestsellingProducts = brand.attributes['best-sellers']
      // eslint-disable-next-line eqeqeq
      .map((id) => this.products?.find((record) => record.id == id))
      .filter((product) => !!product)
    this.sidebarContent = included?.find(
      (record) => record.type === 'sidebar-contents'
    )
  }

  get transformedData() {
    return {
      id: this.brand.id,
      description: this.brand?.attributes?.description,
      name: this.brand?.attributes?.name,
      slugUrl: this.brand.attributes['slug-url'],
      seo: {
        pageTitle: this.brand.attributes.seo?.['page-title'],
        description: this.brand.attributes.seo?.['meta-description']
      },
      billboards: new BrandBillboardsTransformer(this.billboards)
        .transformedData,
      bestsellingProducts: new ProductsTransformer(this.bestsellingProducts, [
        ...this.included,
        this.brand
      ]).transformedData,
      footerContent: this.sidebarContent?.attributes?.content
    }
  }
}
