import LineItemTransformer from './LineItemTransformer'
const EXCLUSIVE_OFFERS_SOURCES = ['shipping_method', 'progress']

export default class CartTransformer {
  constructor(cart) {
    this.cart = cart
  }

  get transformedData() {
    const cartData = {
      id: this.cart.id,
      token: this.cart.attributes.token,
      total: this.cart.attributes.total,
      currency: this.cart.attributes.currency,
      displayTotal: this.cart.attributes['display-total'].toString(),
      displaySubtotal: this.cart.attributes['display-subtotal'].toString(),
      shippingOffers: this.cart.attributes['exclusive-offers']?.filter(
        (item) => EXCLUSIVE_OFFERS_SOURCES.includes(item?.source)
      )
    }

    if (this.cart?.type === 'bag') {
      const transformedLineItems = new LineItemTransformer(
        this.cart.attributes['line-items']
      ).transformedData
      cartData.lineItems = transformedLineItems
      cartData.lineItemsCount = transformedLineItems.length
    }
    return cartData
  }
}
