export default class ProductRecommendationsTransformer {
  constructor(data) {
    this.data = data
  }

  get transformedData() {
    return {
      strategy: this.data.strategy,
      splitKey: this.data.split_key,
      recommendationId: this.data.recommendation_id,
      recommendations: this.transformProductsData()
    }
  }

  transformProductsData() {
    return this.data.recommendations?.map(({ item }) => {
      const product = {
        id: item.product_id,
        brandName: item.brand,
        productName: item.name,
        rating: item.rating || 0,
        slugUrl: item.product_slug,
        featuredVariant: {
          id: item.variant_id,
          name: item.variant,
          imageUrl: item.image_url,
          url: item.variant_uri,
          onSale: item.under_sale,
          saleText: item.sale_text,
          price: item.price_with_currency,
          tags: item.tags || []
        },
        splitKey: this.data.split_key,
        recommendationId: this.data.recommendation_id,
        strategy: this.data.strategy
      }

      if (item.crossed_price_with_currency) {
        product.featuredVariant.originalPrice = item.crossed_price_with_currency
      }
      return product
    })
  }
}
