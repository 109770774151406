<template>
  <GetAppLinks
    :title="$t('layout.getApp.footerTitle')"
    :get-via-text="$t('layout.getApp.getVia')"
  />
</template>

<script>
import GetAppLinks from '~/components/GetAppLinks.vue'

export default {
  name: 'FooterGetApp',
  components: {
    GetAppLinks
  }
}
</script>

<style lang="scss" scoped>
/deep/ .get-app-qr-code-icon {
  width: 30%;
  margin-bottom: 10px;
}

/deep/ .get-app-android-icon {
  width: 118px;
}
/deep/ .get-app-ios-icon {
  width: 102px;
}

/deep/.get-via-text {
  margin-bottom: 5px;
}
</style>
