import billboard from './modules/billboard'
import featureCarousel from './modules/featureCarousel'
import homePageCarousel from './modules/homePageCarousel'
import layoutConfig from './modules/layoutConfig'
import quickLink from './modules/quickLink'
import shopByCampaign from './modules/shopByCampaign'

// get the options out using lodash templates
const options = JSON.parse(`{"namespace":"homepageUI"}`)

const modules = {
  billboard,
  featureCarousel,
  homePageCarousel,
  layoutConfig,
  quickLink,
  shopByCampaign
}

export default ({ store }, inject) => {
  for (const moduleName in modules) {
    store.registerModule(moduleName, modules[moduleName](options), {
      preserveState: Boolean(store.state[moduleName]) // if the store module already exists, preserve it
    })
  }
}
