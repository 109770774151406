const REFRESH_TOKEN_PATH = '/api/auth/v1/token'

function fetchRefreshTokenWithAppFlow(
  axios,
  { refreshToken, deviceId, token }
) {
  return axios.post(
    REFRESH_TOKEN_PATH,
    {
      grant_type: 'refresh_token',
      flow: 'app',
      refresh_token: refreshToken,
      device_id: deviceId
    },
    {
      // Skip the logic of the interceptor for this call to prevent indefinite looping
      // Ref: https://github.com/Flyrell/axios-auth-refresh#skipping-the-interceptor
      skipAuthRefresh: true,
      headers: {
        Authorization: token,
        Cookie: ''
      }
    }
  )
}

function fetchRefreshTokenWithClientFlow(axios) {
  return axios.post(
    REFRESH_TOKEN_PATH,
    {
      grant_type: 'refresh_token',
      flow: 'client'
    },
    {
      // Skip the logic of the interceptor for this call to prevent indefinite looping
      // Ref: https://github.com/Flyrell/axios-auth-refresh#skipping-the-interceptor
      skipAuthRefresh: true,
      withCredentials: true
    }
  )
}

export {
  REFRESH_TOKEN_PATH,
  fetchRefreshTokenWithAppFlow,
  fetchRefreshTokenWithClientFlow
}
