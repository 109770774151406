import get from 'lodash/get'

export const state = () => ({
  categories: [],
  isBookingServiceLoaded: false,
  pages: [],
  beautyClasses: []
})

export const mutations = {
  setCategories(state, categories) {
    state.categories = categories
  },
  setIsBookingServiceLoaded(state, isBookingServiceLoaded) {
    state.isBookingServiceLoaded = isBookingServiceLoaded
  },
  setPages(state, pages) {
    state.pages = pages
  },
  setBeautyClasses(state, beautyClasses) {
    state.beautyClasses = beautyClasses
  }
}

export const getters = {
  categories(state) {
    return get(state, 'categories')
  },
  isBookingServiceLoaded(state) {
    return get(state, 'isBookingServiceLoaded')
  },
  pages(state) {
    return get(state, 'pages')
  },
  beautyClasses(state) {
    return get(state, 'beautyClasses')
  }
}

export const actions = {
  async fetchAll({ commit }) {
    await this.$repositories.bookingServiceCategories
      .index()
      .then((response) => {
        commit('setCategories', response.data)

        this.$repositories.bookingServicePages
          .index()
          .then((response) => {
            commit('setPages', response.data)
            this.$repositories.bookingServiceBeautyClasses
              .index()
              .then((response) => {
                commit('setBeautyClasses', response.data)
                commit('setIsBookingServiceLoaded', true)
              })
              .catch((e) => {})
          })
          .catch((e) => {})
      })
      .catch((e) => {})
  },
  async fetchCategories({ commit }) {
    const {
      data
    } = await this.$repositories.bookingServiceCategories
      .index()
      .catch((e) => {})
    commit('setCategories', data)
  },
  async fetchPages({ commit }) {
    const {
      data
    } = await this.$repositories.bookingServicePages.index().catch((e) => {})
    commit('setPages', data)
  },
  async fetchBeautyClasses({ commit }) {
    const {
      data
    } = await this.$repositories.bookingServiceBeautyClasses
      .index()
      .catch((e) => {})
    commit('setBeautyClasses', data)
  }
}
