<template>
  <SmartLink :native="false" to="/account/point_logs">
    <TopNavigationElement :text="points" :additional-attrs="additionalAttrs" />
  </SmartLink>
</template>

<script>
import { mapGetters } from 'vuex'
import TopNavigationElement from '@sephora-asia/core-top-navigation-element/TopNavigationElement.vue'

export default {
  name: 'TopNavigationUserPoints',

  components: {
    TopNavigationElement
  },

  computed: {
    points() {
      return `${this.userPoints} ${this.$t('dictionary.points')}`
    },
    ...mapGetters({
      userPoints: 'user/points'
    }),
    additionalAttrs() {
      return {
        'data-analytics-id': 'header-click',
        'data-analytics-label': 'point_logs'
      }
    }
  }
}
</script>
