import CategoryTransformer from '../../api/CategoryTransformer.js'

export default () => ({
  namespaced: true,

  state: () => ({
    category: {}
  }),

  mutations: {
    setListingPagesCategory(state, category) {
      state.category = category
    }
  },

  getters: {
    category(state) {
      return state.category
    }
  },

  actions: {
    clearCategory({ commit }) {
      commit('setListingPagesCategory', null)
    },
    async fetchCategory({ commit }, { categoryPath }) {
      commit('setListingPagesCategory', {})

      const { data, included } =
        (await this.$repositories.categories.show({
          id: categoryPath,
          include:
            'billboards,sidebar_content,terms_and_conditions,billboard_configurations,best_selling_products'
        })) || {}

      if (!data) return

      commit(
        'setListingPagesCategory',
        new CategoryTransformer(data, included).transformedData
      )
    }
  }
})
