<template>
  <TopNavigationDropdownCart
    :user-logged-in="userLoggedIn"
    :shipping-offers="shippingOffers"
    :loyalty-points="loyaltyPoints"
    :total-text="totalText"
    :line-items="lineItems"
    :line-item-count="lineItemsCount"
    :loading="loading"
    @remove-line-item="removeLineItem"
    @bag-icon-click="handleBagClick"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import Bugsnag from '@bugsnag/js'
import TopNavigationDropdownCart from './TopNavigationDropdownCart.vue'

export default {
  name: 'TopNavigationDropdownCartContainer',
  components: {
    TopNavigationDropdownCart
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      userLoggedIn: 'user/loggedIn',
      loyaltyPoints: 'user/points',
      totalText: 'cart/subtotal',
      lineItems: 'cart/lineItems',
      lineItemsCount: 'cart/lineItemsCount',
      shippingOffers: 'cart/shippingOffers'
    })
  },
  methods: {
    trackRemoveItemEvent(lineItem) {
      if (lineItem) {
        const data = {
          product_id: lineItem.productId,
          product_name: lineItem.productName,
          variant_id: lineItem.variantId,
          brand: lineItem.brandName,
          price: lineItem.unitPrice,
          quantity: lineItem.quantityValue
        }
        this.$analytics.trackEvent('Remove from Cart', data)
      }
    },
    showLoader() {
      this.loading = true
    },
    hideLoader() {
      this.loading = false
    },
    async removeLineItem(id, showLoader, hideLoader) {
      const lineItem = this.lineItems.find((lineItem) => lineItem.id === id)

      this.showLoader()
      await this.$store
        .dispatch('cart/deleteLineItem', id)
        .then(() => {
          this.trackRemoveItemEvent(lineItem)
        })
        .catch((error) => {
          this.$notify({ text: error, type: 'error' })
        })
        .finally(() => {
          this.hideLoader()
        })
    },
    async handleBagClick() {
      this.showLoader()

      try {
        await this.$store.dispatch('cart/fetch')
      } catch (e) {
        Bugsnag.notify(e)
      } finally {
        this.hideLoader()
      }
    }
  }
}
</script>
