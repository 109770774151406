
import { loadFonts, fontsToLinks } from './utils/fontLoader'

import './font-face.css';

const fonts = [{"preload":true,"source":{"path":require('@sephora-asia/core-styles/assets/fonts/SephoraSerif-Book.woff2'),"format":"woff2"},"classes":["font_sephora_serif","font_sephora_serif_400_normal"]},{"preload":true,"source":{"path":require('@sephora-asia/core-styles/assets/fonts/SephoraSerif-BookItalic.woff2'),"format":"woff2"},"classes":["font_sephora_serif","font_sephora_serif_400_italic"]},{"preload":true,"source":{"path":require('@sephora-asia/core-styles/assets/fonts/SephoraSans-Light.woff2'),"format":"woff2"},"classes":["font_sephora_sans","font_sephora_sans_300_normal"]},{"preload":true,"source":{"path":require('@sephora-asia/core-styles/assets/fonts/SephoraSans-Book.woff2'),"format":"woff2"},"classes":["font_sephora_sans","font_sephora_sans_400_normal"]},{"preload":true,"source":{"path":require('@sephora-asia/core-styles/assets/fonts/SephoraSans-Medium.woff2'),"format":"woff2"},"classes":["font_sephora_sans","font_sephora_sans_500_normal"]},{"preload":true,"source":{"path":require('@sephora-asia/core-styles/assets/fonts/SephoraSans-Bold.woff2'),"format":"woff2"},"classes":["font_sephora_sans","font_sephora_sans_700_normal"]},{"preload":true,"source":{"path":require('@sephora-asia/core-styles/assets/fonts/Avalon-Bold.woff2'),"format":"woff2"},"classes":["font_avalon","font_avalon_700_normal"]},{"preload":true,"source":{"path":require('@sephora-asia/core-styles/assets/fonts/Avalon-Demi.woff2'),"format":"woff2"},"classes":["font_avalon","font_avalon_600_normal"]},{"preload":true,"source":{"path":require('@sephora-asia/core-styles/assets/fonts/Avalon-Book.woff2'),"format":"woff2"},"classes":["font_avalon","font_avalon_400_normal"]}];

if (!process.server){
  loadFonts({"ignoredEffectiveTypes":["2g","slow-2g"],"ignoreLighthouse":false,"unlockDelay":0,"prefetchCount":2});
}

export default function ({ app }) {
  app.head.link.push(...fontsToLinks(fonts));
}
