<template>
  <!-- Container for appsflyer banner to render -->
  <div :id="$options.bannerContainerId" />
</template>

<script>
export default {
  name: 'AppsflyerBannerContainer',
  bannerContainerId: 'af-get-app-banner-container',

  head() {
    if (this.appsflyerWebKey) {
      return {
        script: [
          {
            hid: 'appsflyer-smartbanner',
            type: 'text/javascript',
            innerHTML: `!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "${this.appsflyerWebKey}"}});AF('banners', 'showBanner', { bannerContainerQuery: "#${this.$options.bannerContainerId}", bannerZIndex: 9999, additionalParams: { p1: "v1", p2: "v2"}})`,
            head: true,
            defer: true,
            once: true
          }
        ]
      }
    }
  },

  computed: {
    appsflyerWebKey() {
      return (
        !this.$store.state.globalConfig.isAppsflyerDisabled &&
        this.$store.getters?.['globalConfig/appsflyerWebKey']
      )
    }
  }
}
</script>
