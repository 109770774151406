<template>
  <div ref="observerTargetRef">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ImpressionTrackingContainer',

  props: {
    /**
     * The root element w.r.t which we want to observe our target element for visibility.
     * If not provided or null, the browser's viewport will be used.
     * MDN reference: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#root
     */
    rootElement: {
      type: Object,
      default: null
    },
    /**
     * Sets margin around the root to have an offset on root while calculating intersection with the target.
     * Can have values similar to the CSS margin property.
     * MDN reference: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#rootmargin
     */
    rootMargin: {
      type: String,
      default: '0'
    },
    /**
     * A single number or array of numbers in range 0.0 - 1.0 which
     * indicates at what percentage of the target's visibility the observer's callback should be executed.
     * using value 0.5 indicates callback will be executed when target is 50% visible inside root element.
     * MDN reference: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#threshold
     */
    threshold: {
      type: Array | Number,
      default: () => [0],
      validator: (value) => {
        if (Array.isArray(value)) {
          return value.every((v) => typeof v === 'number' && v >= 0 && v <= 1)
        }
        return typeof value === 'number' && value >= 0 && value <= 1
      }
    },
    /**
     * If set true, disconnect observer once the target element is visible for first time.
     */
    observeOnce: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      observer: null
    }
  },

  mounted() {
    this.initObserver()
  },

  beforeDestroy() {
    this.disconnectObserver()
  },

  methods: {
    initObserver() {
      try {
        if (!this.$slots?.default || this.observer) return

        this.observer = new IntersectionObserver(this.onIntersection, {
          root: this.rootElement,
          rootMargin: this.rootMargin,
          threshold: this.threshold
        })

        this.observer.observe(this.$refs.observerTargetRef)
      } catch {}
    },

    onIntersection([entry]) {
      if (entry.isIntersecting && this.$slots?.default) {
        this.$emit('track-impression', entry)

        if (this.observeOnce) this.disconnectObserver()
      }
    },

    disconnectObserver() {
      this.observer?.disconnect?.()
    }
  }
}
</script>
