<script>
import { mapGetters } from 'vuex'
import get from 'lodash/get'

export default {
  name: 'InitDataLayerContainer',

  computed: {
    ...mapGetters({
      id: 'user/id',
      isEmployee: 'user/isEmployee',
      age: 'user/age',
      cardNumber: 'user/cardNumber',
      emailSHA256: 'user/emailSHA256',
      emailMD5: 'user/emailMD5',
      mobileNumberMD5: 'user/mobileNumberMD5',
      loyaltyTier: 'user/loyaltyTier',
      loyaltyPoints: 'user/loyaltyPoints',
      tierPointsCounter: 'user/tierPointsCounter'
    })
  },

  methods: {
    notifyDataLoaded() {
      window.onNuxtReady(() => {
        this.$analytics.trackEvent('data_layer_loaded')
      })
    },
    setFirstDataLayerElement() {
      const firstElement = {
        user: {
          id: this.id,
          is_employee: this.isEmployee,
          age: this.age,
          card_number: this.cardNumber,
          email_sha256: this.emailSHA256,
          email_md5: this.emailMD5,
          mobile_number_md5: this.mobileNumberMD5,
          loyalty_tier: this.loyaltyTier,
          loyalty_points: this.loyaltyPoints,
          tier_points_counter: this.tierPointsCounter
        },
        cart: {},
        site: {
          locale: this.$i18n.countryCode.toLowerCase(),
          language: this.$i18n.locale,
          mobile: this.$device.isMobile,
          status: 200
        }
      }
      window.dataLayer = window.dataLayer || []
      if (get(window, 'dataLayer[0].user', null)) {
        window.dataLayer[0] = firstElement
      } else {
        window.dataLayer.unshift(firstElement)
      }
      this.notifyDataLoaded()
    }
  },

  render() {
    this.setFirstDataLayerElement()

    return null
  }
}
</script>
