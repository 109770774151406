export default class BrandBillboardsTransformer {
  constructor(billboards) {
    this.billboards = billboards.filter((record) =>
      Object.keys(record.attributes)
    )
  }

  get transformedData() {
    return this.billboards.map((billboard) => {
      return {
        id: billboard.id,
        title: billboard.attributes.title,
        url: billboard.attributes.link,
        imgSrc: billboard.attributes['image-url'],
        altText: billboard.attributes['alt-text'],
        target: billboard.attributes['new-tab'] && '_blank',
        rel: billboard.attributes['new-tab'] && 'noopener',
        termsAndConditions: billboard.attributes['terms-and-conditions'],
        videoId: billboard.attributes['banner-video-id'],
        hasMainBanner: billboard.attributes['main-banner'],
        isMobileOnly: billboard.attributes['mobile-only']
      }
    })
  }
}
