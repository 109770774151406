import BrandTransformer from '../../api/BrandTransformer.js'

export default () => ({
  namespaced: true,

  state: () => ({
    brand: {}
  }),

  mutations: {
    setListingPagesBrand(state, brand) {
      state.brand = brand
    }
  },

  getters: {
    brand(state) {
      return state.brand
    }
  },

  actions: {
    clearBrand({ commit }) {
      commit('setListingPagesBrand', null)
    },
    async fetchBrand({ commit }, { brandId }) {
      commit('setListingPagesBrand', {})

      const { data, included } =
        (await this.$repositories.brands.show({
          id: brandId,
          include:
            'billboards,sidebar_content,terms_and_conditions,billboard_configurations,best_selling_products'
        })) || {}

      if (!data) return

      commit(
        'setListingPagesBrand',
        new BrandTransformer(data, included).transformedData
      )
    }
  }
})
