<template>
  <SidebarNavItem
    :text="$t('dictionary.logout')"
    :level="1"
    :has-separator="false"
    :icon-src="$options.locals.logoutIcon"
    :icon-alt-text="$t('dictionary.logout')"
    @click="signOut"
  />
</template>

<script>
import SidebarNavItem from '@sephora-asia/core-sidebar-nav-item/SidebarNavItem.vue'
import LogoutIcon from '~/assets/images/logout.svg'
export default {
  name: 'SidebarNavMenuLogout',
  components: {
    SidebarNavItem
  },
  methods: {
    signOut() {
      this.$emit('toggle-sidebar-nav')
      this.$authManager.signOut()
    }
  },
  locals: {
    logoutIcon: LogoutIcon
  }
}
</script>
