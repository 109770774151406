export default ($axios) => (resource) => {
  const methods = {
    /**
     * Base get method aliased to `index` and `show`.
     */
    get(params = {}, headers = {}) {
      if (params.id) {
        const resourceWithId = resource + '/' + params.id
        delete params.id
        return $axios.$get(resourceWithId, { params, headers })
      } else {
        return $axios.$get(resource, { params, headers })
      }
    },
    /**
     * Base post method aliased to `create`.
     */
    post(data, params, headers = {}) {
      return $axios.$post(resource, data, { params, headers })
    },
    /**
     * Base put method aliased to `update`.
     */
    put(params, headers = {}) {
      const resourceWithId = resource + '/' + params.id
      delete params.id

      return $axios.$put(resourceWithId, { headers })
    },
    /**
     * Base delete method aliased to `destroy`.
     */
    delete(params = {}, headers = {}) {
      // Transforms the url
      // from: /api/v2.4/resource?id=123?include=x,y,z
      // to: /api/v2.4/resource/123?include=x,y,z

      // Do not override the resource itself
      if (params.id) {
        const resourceWithId = resource + '/' + params.id
        delete params.id
        return $axios.$delete(resourceWithId, { params, headers })
      } else {
        return $axios.$delete(resource, { params, headers })
      }
    }
  }

  Object.defineProperties(methods, {
    /**
     * Use to create new records. Alias of `post`.
     */
    create: {
      get() {
        return methods.post
      }
    },
    /**
     * Use to delete resources. Alias of `delete`.
     */
    destroy: {
      get() {
        return methods.delete
      }
    },
    /**
     * Use when you intend to get multiple resources. Alias of `get`.
     **/
    index: {
      get() {
        return methods.get
      }
    },
    /**
     * Use when you intend to get a single resource. Alias of `get`.
     **/
    show: {
      get() {
        return methods.get
      }
    },
    /**
     * Use to update resources. Alias of `put`.
     */
    update: {
      get() {
        return methods.put
      }
    }
  })

  return methods
}
