<template>
  <Dropdown class="beauty-pass-dropdown">
    <TopNavigationElement
      :text="$t('layout.shared.beautyPass')"
      :icon-src="$options.locals.beautyPassIcon"
    />
    <template slot="content">
      <SmartLink
        :native="false"
        :to="'/beauty-pass'"
        class="dropdown-item"
        v-text="$t('layout.navBar.beautyPassBenefits')"
      />
      <SmartLink
        :native="false"
        :to="'/rewards-boutique'"
        class="dropdown-item"
        v-text="$t('layout.navBar.rewardsBoutique')"
      />
      <SmartLink
        v-if="loggedIn"
        :native="false"
        :to="'/account/point_logs'"
        class="dropdown-item"
        v-text="$t('layout.navBar.pointsSummary')"
      />
    </template>
  </Dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import Dropdown from '@sephora-asia/core-dropdown/Dropdown.vue'
import TopNavigationElement from '@sephora-asia/core-top-navigation-element/TopNavigationElement.vue'
import BeautyPassIcon from '~/assets/images/beauty-pass-white.svg'

export default {
  name: 'TopNavigationDropdownBeautyPass',
  components: {
    Dropdown,
    TopNavigationElement
  },
  locals: {
    beautyPassIcon: BeautyPassIcon
  },
  computed: {
    ...mapGetters({
      loggedIn: 'user/loggedIn'
    })
  }
}
</script>

<style lang="scss" scoped>
.beauty-pass-dropdown {
  margin-left: 20px;

  ::v-deep .dropdown-content {
    max-width: 240px;
    text-transform: capitalize;
  }
}
</style>
