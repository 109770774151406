import FiltersTransformer from '../../api/FiltersTransformer.js'

export default () => ({
  namespaced: true,

  state: () => ({
    brandFilters: [],
    categoryFilters: [],
    categoryFilterTrail: [],
    currentCategoryFilter: {},
    currentFilterType: {},
    filterParams: { brands: [], type: {}, others: [] },
    initialFilterUrl: {},
    otherFilters: [],
    priceRangeFilter: {},
    searchBrand: '',
    showFiltersMobile: false,
    showViewResultsCTAButton: false,
    typeFilters: []
  }),

  mutations: {
    setListingPageBrandFilters(state, brandFilters) {
      state.brandFilters = brandFilters
    },
    setListingPageCategoryFilters(state, categoryFilters) {
      state.categoryFilters = categoryFilters
    },
    setListingPageCurrentCategoryFilter(state, currentCategoryFilter) {
      state.currentCategoryFilter = currentCategoryFilter
    },
    setListingPageCurrentFilterType(state, currentFilterType) {
      state.currentFilterType = currentFilterType
    },
    setListingPageInitialFilterUrl(state, initialFilterUrl) {
      state.initialFilterUrl = initialFilterUrl
    },
    setListingPageOtherFilters(state, otherFilters) {
      state.otherFilters = otherFilters
    },
    setListingPageBrandFilterParams(state, brandFilterParams) {
      state.filterParams.brands = brandFilterParams
    },
    setListingPageOtherFilterParams(state, otherFilterParams) {
      state.filterParams.others = otherFilterParams
    },
    setListingPageTypeFilterParams(state, typeFilterParams) {
      // Merge state with new typeFilterParam object in order to make nested objects reactive
      //
      // Ref: https://v2.vuejs.org/v2/guide/reactivity.html#For-Objects (read the last para in this link)
      // Sometimes you may want to assign a number of properties to an
      // existing object, for example using Object.assign() or _.extend().
      // However, new properties added to the object will not trigger changes.
      // In such cases, create a fresh object with properties from both the
      // original object and the mixin object

      state.filterParams.type = {
        ...state.filterParams.type,
        ...typeFilterParams
      }
    },
    clearListingPageFilterParams(state) {
      const filterParams = { brands: [], type: {}, others: [] }

      Object.keys(state.filterParams.type).forEach(
        (slugUrl) => (filterParams.type[slugUrl] = [])
      )

      state.filterParams = filterParams
    },
    setListingPagePriceRangeFilter(state, priceRangeFilter) {
      state.priceRangeFilter = priceRangeFilter
    },
    setListingPageTypeFilters(state, typeFilters) {
      state.typeFilters = typeFilters
    },
    setListingPageCategoryFilterTrail(state, args) {
      if (args.pop) {
        state.categoryFilterTrail.splice(-1)
      } else if (args.push) {
        state.categoryFilterTrail.push(args.push)
      } else if (args.clear) {
        state.categoryFilterTrail = []
      }
    },
    setListingPageSearchBrand(state, searchBrand) {
      state.searchBrand = searchBrand
    },
    setShowFiltersMobile(state, showFiltersMobile) {
      state.showFiltersMobile = showFiltersMobile
    },
    setShowViewResultsCTAButton(state, showViewResultsCTAButton) {
      state.showViewResultsCTAButton = showViewResultsCTAButton
    }
  },

  getters: {
    brandFilters(state) {
      return state.brandFilters
    },
    categoryFilters(state) {
      return state.categoryFilters
    },
    currentCategoryFilter(state) {
      return state.currentCategoryFilter
    },
    currentFilterType(state) {
      return state.currentFilterType
    },
    filterParams(state) {
      return state.filterParams
    },
    initialFilterUrl(state) {
      return state.initialFilterUrl
    },
    otherFilters(state) {
      return state.otherFilters
    },
    priceRangeFilter(state) {
      return state.priceRangeFilter
    },
    typeFilters(state) {
      return state.typeFilters
    },
    categoryFilterTrail(state) {
      return state.categoryFilterTrail
    },
    findCategoryFiltersby: (state) => (attribute, value) => {
      return (
        state.categoryFilters.find(
          (category) => category[attribute] === value
        ) || {}
      )
    },
    ancestorCategoryFilterIdsFor: (state, getters) => (child) => {
      if (!child.parentId) return [child.id]

      const parent = state.categoryFilters.find(
        (category) => category.id === child.parentId
      )

      return [getters.ancestorCategoryFilterIdsFor(parent), parent.id].flat()
    },
    searchBrand(state) {
      return state.searchBrand
    },
    showFiltersMobile(state) {
      return state.showFiltersMobile
    },
    showViewResultsCTAButton(state) {
      return state.showViewResultsCTAButton
    }
  },

  actions: {
    async fetchFilters({ commit }, { entityType, entityValue }) {
      const filters = {}
      filters[`filter[${entityType}]`] = entityValue

      try {
        const { data, included } =
          await this.$repositories.productFilters.index({
            ...filters,
            include: 'brands,categories,filter_types.filter_values'
          })
        const filterTransformer = new FiltersTransformer(data, included)

        commit(
          'setListingPageBrandFilters',
          filterTransformer.transformedBrandFiltersData()
        )
        commit(
          'setListingPageCategoryFilters',
          filterTransformer.transformedCategoryFiltersData()
        )
        commit(
          'setListingPageTypeFilters',
          filterTransformer.transformedTypeFiltersData()
        )
        commit(
          'setListingPageOtherFilters',
          filterTransformer.transformedOtherFiltersData()
        )
        commit(
          'setListingPagePriceRangeFilter',
          filterTransformer.transformedPriceRangeFilterData()
        )
      } catch (e) {}
    }
  }
})
