<template>
  <div class="content">
    <div class="title">
      {{ title }}
    </div>
    <img
      v-if="$device.isDesktopOrTablet"
      v-lazy-load
      :data-src="getAppQRCodeIcon"
      :alt="$t('layout.getApp.headerTitle')"
      class="get-app-qr-code-icon"
    />
    <p v-if="$device.isDesktopOrTablet" class="get-via-text">
      {{ getViaText }}
    </p>
    <SmartLink
      v-if="$device.isDesktopOrTablet || $device.isIos"
      :native="false"
      :to="$options.locals.getAppIosUrl"
      target="_blank"
      rel="noopener"
      data-analytics-id="get-app-banner"
      data-analytics-label="GET IOS App"
    >
      <img
        v-lazy-load
        :data-src="$options.locals.getAppIosIcon"
        :alt="$t('layout.getApp.getIos')"
        class="get-app-ios-icon"
      />
    </SmartLink>
    <SmartLink
      v-if="$device.isDesktopOrTablet || $device.isAndroid"
      :native="false"
      :to="$options.locals.getAppAndroidUrl"
      target="_blank"
      rel="noopener"
      data-analytics-id="get-app-banner"
      data-analytics-label="GET Android App"
    >
      <img
        v-lazy-load
        :data-src="$options.locals.getAppAndroidIcon"
        :alt="$t('layout.getApp.getAndroid')"
        class="get-app-android-icon"
      />
    </SmartLink>
  </div>
</template>

<script>
import GetAppIosIcon from '~/assets/images/apple-store-badge.svg'
import GetAppAndroidIcon from '~/assets/images/playstore-badge.png'

export default {
  name: 'GetAppLinks',
  props: {
    title: {
      type: String,
      required: true
    },
    getViaText: {
      type: String,
      required: true
    }
  },
  locals: {
    getAppIosIcon: GetAppIosIcon,
    getAppAndroidIcon: GetAppAndroidIcon,
    getAppIosUrl:
      'https://app.appsflyer.com/id1152899196?pid=Onsite&amp;c=SG_Header_iOS',
    getAppAndroidUrl:
      'https://app.appsflyer.com/com.sephora.digital?pid=Onsite&amp;c=SG_Header_Android'
  },
  computed: {
    getAppQRCodeIcon() {
      return require(`~/assets/images/qr-codes/get-app-${this.$i18n.countryCode.toLowerCase()}.png`)
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  padding-top: 0;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.content {
  display: block;
  text-align: center;
}
</style>
