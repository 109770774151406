import { render, staticRenderFns } from "./FooterCopyright.vue?vue&type=template&id=0a36d8e7&scoped=true&functional=true&"
import script from "./FooterCopyright.vue?vue&type=script&lang=js&"
export * from "./FooterCopyright.vue?vue&type=script&lang=js&"
import style0 from "./FooterCopyright.vue?vue&type=style&index=0&id=0a36d8e7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "0a36d8e7",
  null
  
)

export default component.exports