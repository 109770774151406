import { joinURL } from 'ufo'

export function getImage(
  src,
  { modifiers, baseURL } = {},
  { options, nuxtContext, $img }
) {
  const { width, height } = modifiers
  const edits = { resize: {} }
  const defaultBaseUrl = { url: joinURL(baseURL, '', src) }

  if (width) {
    edits.resize.width = width
  }

  if (height) {
    edits.resize.height = height
  }

  try {
    const url = new URL(src)

    const { origin, pathname, search } = url

    if (pathname && origin && origin.includes('image-optimizer')) {
      const options = { edits }
      const stringifiedOptions = JSON.stringify(options)
      const base64EncodedOptions = Buffer.from(stringifiedOptions).toString(
        'base64'
      )
      return {
        url: `${joinURL(origin, base64EncodedOptions, pathname)}${search}`
      }
    } else {
      return defaultBaseUrl
    }
  } catch {
    return defaultBaseUrl
  }
}
