export default class AdTransformer {
  constructor(ad) {
    this.ad = ad
  }

  get transformedData() {
    if (!this.ad) return {}

    return {
      id: this.ad.id,
      iconType: this.ad.attributes['icon-type'].replace('_', '-'),
      title: this.ad.attributes['short-title'],
      variantIds: this.ad.attributes['variant-ids']
    }
  }
}
