export default class RecentProductsTransformer {
  constructor(products) {
    this.products = products
  }

  get transformedData() {
    return this.products.map((product) => ({
      id: product.id,
      brandName: product.attributes['brand-name'],
      imgSrc: product.attributes['closeup-image-urls'][0],
      price: product.attributes['display-price'],
      rating: product.attributes.rating,
      slug: product.attributes['slug-url'],
      name: product.attributes.name,
      type: product.type
    }))
  }
}
