<template>
  <transition
    v-if="displayOverlay"
    appear
    name="overlay"
    @after-enter="onOverlayShown"
  >
    <Modal :separator="false" @close="displayOverlay = false">
      <SmartLink :native="false" :to="overlay.url">
        <nuxt-img :src="overlay.image" :alt="overlay.name" />
      </SmartLink>
    </Modal>
  </transition>
</template>

<script>
import SmartLink from '@sephora-asia/common-ui/components/SmartLink.vue'
import Modal from '@sephora-asia/core-modal/Modal.vue'
import debounce from 'lodash/debounce'

import { mapGetters } from 'vuex'

export default {
  name: 'Overlay',
  components: {
    Modal,
    SmartLink
  },

  data() {
    return {
      displayOverlay: false
    }
  },

  computed: {
    ...mapGetters({
      overlay: 'overlay/overlay',
      loyaltyTier: 'user/loyaltyTier'
    })
  },

  watch: {
    '$route.path': 'debouncedRequestOverlay'
  },

  mounted() {
    // Listen for storage events from other tabs to sync up Overlays data.
    // Note: Storage event emitted by a tab will not be received by the same tab as this won't work on the same [browsing context](https://developer.mozilla.org/en-US/docs/Glossary/Browsing_context) that is making the changes.
    // Ref: https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
    window.onstorage = (event) => {
      // Ignore clear(), removeItem() & other events
      if (!event.newValue || !event.key.startsWith('OVERLAY')) return

      this.onOverlayEventMessage(event)
    }

    this.syncOverlayDataAcrossTabs({
      action: 'OVERLAY_INIT_TAB',
      data: {}
    })

    // Delay a bit to let all tabs sync up first
    this.debouncedRequestOverlay()
  },

  methods: {
    debouncedRequestOverlay: debounce(
      async function () {
        // Avoid API call if any overlay is shown on the same page with the same loyalty status
        if (
          Object.values(this.getSession()).find(
            ({ path, loyaltyStatus }) =>
              path === this.$route.path && loyaltyStatus === this.loyaltyTier
          )
        )
          return

        await this.$store.dispatch('overlay/fetchOverlay', this.$route.path)

        if (this.overlay?.id)
          this.displayOverlay = !this.getSession()[this.overlay.id]
      },
      500,
      {
        leading: false,
        trailing: true
      }
    ),

    syncOverlayDataAcrossTabs({ action, data }) {
      localStorage.setItem(action, JSON.stringify(data))

      // remove the item so that if next time the same key value pair is stored, it will not emit a storage event
      localStorage.removeItem(action)
    },

    onOverlayEventMessage({ key, newValue }) {
      switch (key) {
        case 'OVERLAY_INIT_TAB':
          this.syncOverlayDataAcrossTabs({
            action: 'OVERLAY_DATA',
            data: this.getSession()
          })
          break
        case 'OVERLAY_DATA': {
          this.updateSession(JSON.parse(newValue) || {})
          break
        }
      }
    },
    onOverlayShown() {
      const payload = {
        [this.overlay.id]: {
          path: this.$route.path,
          loyaltyStatus: this.loyaltyTier
        }
      }

      this.syncOverlayDataAcrossTabs({
        action: 'OVERLAY_DATA',
        data: payload
      })

      this.updateSession(payload)
    },
    updateSession(data) {
      sessionStorage.setItem(
        'OVERLAY_SESSION',
        JSON.stringify({
          ...this.getSession(),
          ...data
        })
      )
    },
    getSession() {
      return JSON.parse(sessionStorage.getItem('OVERLAY_SESSION')) || {}
    }
  }
}
</script>

<style lang="scss" scoped>
.modal /deep/ {
  transition: opacity 0.25s ease-in-out;

  .modal-content {
    // Need to keep !important in case specificity increases in modal styling then this will break overlay UI
    background-color: transparent !important;
    width: auto !important;
    max-width: calc(min(78vw, 800px));

    .modal-header {
      position: absolute;
      right: 0;
      top: 0;
      z-index: $zindex-tooltip;

      .close-button {
        margin: -25px -25px 0 0;
        cursor: pointer;
        border-radius: 50%;
        opacity: 0.7;
        width: 22px;
        height: 22px;
        background-size: 30px;
        background-color: $color-base-grey-50;
        transition: opacity 0.25s ease-in-out;

        &:hover {
          opacity: 1;
        }
      }
    }
    .modal-body {
      padding: 0;
    }
  }
}

.overlay-enter,
.overlay-leave-to {
  opacity: 0;
}
.overlay-enter-active {
  /deep/ .modal-content {
    animation: bounce 0.25s ease-in;
  }
  /deep/ .modal-overlay {
    opacity: 0.6;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    transform: scale(1.05);
  }
  85% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
