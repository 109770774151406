<template>
  <Accordion :initial-expanded="false" class="footer-list-mobile">
    <SidebarNavItem
      :text="title"
      :level="1"
      :icon-alt-text="title"
      :has-separator="false"
    />
    <template #content>
      <div class="item">
        <SidebarNavItem
          v-for="(item, index) in list"
          :key="index"
          :text="item.text"
          :url="item.path"
          :level="2"
          :has-separator="false"
        />
        <slot />
      </div>
    </template>
    <template #toggle-icon="slotProps">
      <img
        v-lazy-load
        :data-src="$options.locals.arrowDown"
        :alt="$t('dictionary.toggle')"
        :class="slotProps.toggledClass"
        class="arrow"
      />
    </template>
  </Accordion>
</template>

<script>
import Accordion from '@sephora-asia/core-accordion/Accordion.vue'
import SidebarNavItem from '@sephora-asia/core-sidebar-nav-item/SidebarNavItem.vue'
import ArrowDown from '~/assets/images/arrow-down.svg'
export default {
  name: 'FooterListMobile',
  components: {
    Accordion,
    SidebarNavItem
  },
  props: {
    title: {
      type: String,
      required: true
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  locals: {
    arrowDown: ArrowDown
  }
}
</script>

<style lang="scss" scoped>
.arrow {
  padding: 0;
  margin: 0;
  height: 15px;
  width: 15px;

  &.collapse {
    transform: rotateZ(-180deg);
  }
}

.footer-list-mobile {
  border-bottom: 1px solid $grey;

  &:first-child {
    border-top: 1px solid $grey;
  }
}

.item {
  margin-bottom: 20px;
}

/deep/ .nav-item-link.first {
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: 10px;
}

/deep/ .nav-item-link.second {
  font-size: 14px;
  padding: 0 0 7px 0;
}

/deep/ .expand:after,
.collapse:after {
  content: '';
}
</style>
