<script>
const SOCIAL_PROVIDER = {
  googleOneTap: {
    event: 'User Sign in',
    data: {
      category: 'User',
      action: 'Sign in',
      label: 'google one tap'
    }
  }
}

export default {
  name: 'GlobalEventsHandler',

  mounted() {
    // Use $eventBus instead of $nuxt as it accessible app wide
    this.$eventBus.$on('social-sign-in', this.trackSocialSignIn)
  },

  beforeDestroy() {
    this.$eventBus.$off('social-sign-in', this.trackSocialSignIn)
  },

  methods: {
    trackSocialSignIn(providerName) {
      const socialProvider = SOCIAL_PROVIDER[providerName]

      if (!socialProvider) return

      this.$analytics.trackEvent(socialProvider.event, socialProvider.data)
    }
  },

  render() {
    return null
  }
}
</script>
