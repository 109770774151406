import { logDebug } from '../utils.js'
import BasicModule from './BasicModule.js'

const gtmScriptId = 'gtm-module'

export default class GTMModule extends BasicModule {
  /**
   * Load GTM script tag
   * @param {String}  id  GTM ID
   * @param {Object}  params query params object
   *   available keys:
   *    async
   *    defer
   *    queryParams
   */
  loadScript(id, config = {}) {
    if (!id) {
      logDebug('GTM: Missing ID')
      return
    }

    if (this.hasScript()) {
      logDebug('GTM: script is already loaded')
      return
    }

    const script = document.createElement('script')

    script.id = gtmScriptId

    script.async = config.async !== undefined ? config.async : true
    script.defer = config.defer !== undefined ? config.defer : true

    const queryString = new URLSearchParams({
      id,
      ...(config.queryParams || {})
    })
    script.src = `https://www.googletagmanager.com/gtm.js?${queryString}`

    document.body.appendChild(script)
  }

  /**
   * Check if GTM script is in the document
   * @return {boolean}
   */
  hasScript() {
    return document.getElementById(gtmScriptId) !== null
  }

  init(config = {}) {
    this.initDataLayer()
    this.loadScript(config.id, config)
  }

  trackEvent(eventName, params = {}) {
    const data = Object.assign({}, params, { event: eventName })
    window.dataLayer.push(data)
  }

  initDataLayer() {
    // initialise dataLayer for client side
    if (process.client) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'gtm.js',
        'gtm.start': new Date().getTime()
      })
    }
  }
}
