import { AvailableLanguages, CountryCodes } from '../config/i18n.js'

function availableLanguages(countryCode) {
  return AvailableLanguages[countryCode.toUpperCase()]
}

function isValidCountryCode(countryCode) {
  return CountryCodes.includes(countryCode.toUpperCase().trim())
}

function defaultLanguageCode(countryCode) {
  return AvailableLanguages[countryCode.toUpperCase()][0].toLowerCase()
}

function formattedLanguageCode(languageCode) {
  return languageCode.toLowerCase().replace('lng_', '').trim()
}

function isLanguageAvailable(countryCode, languageCode) {
  return AvailableLanguages[countryCode.toUpperCase()].includes(
    formattedLanguageCode(languageCode)
  )
}

function switchableLanguages(countryCode, currentLanguageCode) {
  return AvailableLanguages[countryCode.toUpperCase()].filter(
    (languageCode) => languageCode !== currentLanguageCode.toLowerCase()
  )
}

export {
  availableLanguages,
  isValidCountryCode,
  defaultLanguageCode,
  formattedLanguageCode,
  isLanguageAvailable,
  switchableLanguages
}
