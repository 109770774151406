import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _67ea6854 = () => interopDefault(import('../pages/sandbox-web.vue' /* webpackChunkName: "pages/sandbox-web" */))
const _34edd4d9 = () => interopDefault(import('../node_modules/@sephora-asia/homepage-ui/pages/index.vue' /* webpackChunkName: "" */))
const _4075545e = () => interopDefault(import('../node_modules/@sephora-asia/listing-pages-ui/pages/brands/index.vue' /* webpackChunkName: "" */))
const _28a965be = () => interopDefault(import('../node_modules/@sephora-asia/listing-pages-ui/pages/categories/index.vue' /* webpackChunkName: "" */))
const _d2511aa6 = () => interopDefault(import('../node_modules/@sephora-asia/listing-pages-ui/pages/search/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/sandbox-web",
    component: _67ea6854,
    name: "sandbox-web"
  }, {
    path: "/",
    component: _34edd4d9,
    name: "index"
  }, {
    path: "/brands/:id/:topCategory?/:category?/:subCategory?",
    component: _4075545e,
    name: "brand-listing-page"
  }, {
    path: "/categories/:topCategory/:category?/:subCategory?",
    component: _28a965be,
    name: "category-listing-page"
  }, {
    path: "/search/:topCategory?/:category?/:subCategory?",
    component: _d2511aa6,
    name: "search-listing-page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
