<template>
  <SmartLink :native="false" to="/sign_in">
    <TopNavigationElement
      :text="$t('dictionary.signIn')"
      :icon-src="$options.locals.myAccountIcon"
      :additional-attrs="additionalAttrs"
    />
  </SmartLink>
</template>

<script>
import TopNavigationElement from '@sephora-asia/core-top-navigation-element/TopNavigationElement.vue'
import MyAccountIcon from '~/assets/images/my-account.svg'

export default {
  name: 'TopNavigationSignIn',

  components: {
    TopNavigationElement
  },

  locals: {
    myAccountIcon: MyAccountIcon
  },

  computed: {
    additionalAttrs() {
      return {
        'data-analytics-id': 'header-click',
        'data-analytics-label': 'sign_in'
      }
    }
  }
}
</script>
