<template>
  <div class="footer-payment-options column">
    <div class="title">
      {{ title }}
    </div>
    <div class="columns is-mobile is-multiline is-marginless">
      <div
        v-for="paymentOption in paymentOptions"
        :key="paymentOption"
        class="column is-narrow is-paddingless icon-container"
      >
        <SpriteIcon :name="paymentOption" />
      </div>
    </div>
  </div>
</template>

<script>
import SpriteIcon from '@sephora-asia/core-sprite-icon/SpriteIcon.vue'
export default {
  components: {
    SpriteIcon
  },

  props: {
    paymentOptions: {
      type: Array,
      required: true
    },

    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-payment-options {
  padding-top: 0;

  @include desktop {
    padding-top: 10px;
    padding-left: 0;
  }
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 5px;

  @include mobile {
    margin-top: 10px;
  }
}

.icon-container {
  margin: 8px;
}
</style>
