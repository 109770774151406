import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getLocaleMessagesFor } from '~/locales/index.js'

import {
  COOKIE_MAX_AGE,
  COUNTRY_CODE_COOKIE_KEY,
  DEFAULT_COUNTRY_CODE,
  LANGUAGE_CODE_COOKIE_KEY,
  X_SITE_COUNTRY_HEADER_KEY
} from '../../config/i18n.js'

import {
  availableLanguages,
  isValidCountryCode,
  defaultLanguageCode,
  formattedLanguageCode,
  isLanguageAvailable
} from '../../utils/i18n.js'

import canonicalizeUrlHelper from '../../utils/canonical-url-helper.js'

Vue.use(VueI18n)

function getCountryCodeFromHostname(req) {
  if (!(req && req.headers && req.headers.host)) {
    return
  }

  // Remove port number in case of development env
  const url = req.headers.host.replace(/:*[0-9]/g, '')

  let countryCode

  // For staging URLs like luxola-development-th.sephora-asia.net
  if (url.match(/-[a-z]{2}\./)) {
    countryCode = url.match(/-[a-z]{2}\./)[0].replace(/[^a-zA-Z]/g, '')
  } else {
    countryCode = url.split('.').pop().trim()
  }

  return isValidCountryCode(countryCode) && countryCode
}

function getCountryCodeFromHeader(req) {
  if (!(req && req.headers && req.headers[X_SITE_COUNTRY_HEADER_KEY])) {
    return
  }

  return (
    isValidCountryCode(req.headers[X_SITE_COUNTRY_HEADER_KEY]) &&
    req.headers[X_SITE_COUNTRY_HEADER_KEY]
  )
}

function getCountryCodeResources(app, req, query) {
  const countryCodeResources = [DEFAULT_COUNTRY_CODE]

  if (process.server) {
    const hostnameCountryCode = getCountryCodeFromHostname(req)
    const headerCountryCode = getCountryCodeFromHeader(req)

    countryCodeResources.unshift(hostnameCountryCode, headerCountryCode)
  } else {
    const cookieCountryCode = app.$cookies.get(COUNTRY_CODE_COOKIE_KEY)
    const queryCountryCode = query && query.country_code

    countryCodeResources.unshift(queryCountryCode, cookieCountryCode)
  }

  return countryCodeResources
}

function getCountryCode(app, req, query) {
  return getCountryCodeResources(app, req, query)
    .filter(Boolean) // Filter out undefined values
    .find((countryCode) => isValidCountryCode(countryCode))
    .toUpperCase()
}

function getLanguageCode(app, countryCode, query) {
  const languageCode =
    query?.language || query?.language_code || app.$cookies.get(LANGUAGE_CODE_COOKIE_KEY)

  return getValidLanguageCode(countryCode, languageCode)
}

function getValidLanguageCode(countryCode, languageCode) {
  if (languageCode && isLanguageAvailable(countryCode, languageCode)) {
    return formattedLanguageCode(languageCode)
  }

  return defaultLanguageCode(countryCode)
}

function switchCountry(app, countryCode) {
  if (!isValidCountryCode(countryCode)) {
    countryCode = DEFAULT_COUNTRY_CODE
  }

  const countryCodeCookie = app.$cookies.get(COUNTRY_CODE_COOKIE_KEY)

  app.i18n.countryCode = countryCode
  countryCode = countryCode.toLowerCase()

  if (countryCodeCookie && countryCodeCookie.toLowerCase() === countryCode) {
    return
  }

  app.$cookies.set(COUNTRY_CODE_COOKIE_KEY, countryCode, {
    maxAge: COOKIE_MAX_AGE,
    path: '/'
  })
}

const switchLanguage = (app) => (languageCode) => {
  if (!languageCode) {
    return
  }

  app.i18n.locale = getValidLanguageCode(app.i18n.countryCode, languageCode)

  const languageCodeCookie = app.$cookies.get(LANGUAGE_CODE_COOKIE_KEY)
  languageCode = languageCode.toLowerCase()

  if (
    languageCodeCookie &&
    formattedLanguageCode(languageCodeCookie) === languageCode
  ) {
    return
  }

  app.$cookies.set(LANGUAGE_CODE_COOKIE_KEY, `lng_${languageCode}`, {
    maxAge: COOKIE_MAX_AGE,
    path: '/'
  })
}

const reloadWithLangQuery = (app) => (languageCode) => {
  const { path, query = {} } = { ...app.router?.app?._route }
  let newQuery = ''

  delete query.language
  const queryEntries = Object.entries(query)

  // Build newQuery with langQuery as the first query, for non-default languages only (This disallows random languages)
  const nonDefaultLanguages = availableLanguages(app.i18n.countryCode).slice(1)
  if (nonDefaultLanguages.includes(languageCode)) {
    newQuery += `language=${languageCode}`
  }

  // Build queryString and add to newQuery
  if (queryEntries.length) {
    const queryString = queryEntries.map((entry) => entry.join('=')).join('&')
    const queryPrefix = newQuery ? '&' : ''
    newQuery += `${queryPrefix + queryString}`
  }

  const newPath = newQuery ? [path, newQuery].join('?') : path

  globalThis.location?.replace(newPath)
}

const setLanguagePreference = (app) => (languageCode) => {
  if (languageCode === app.i18n.locale) return

  switchLanguage(app)(languageCode)
  app.$notify({
    text: app.i18n.t('notices.languageUpdated'),
    type: 'success'
  })

  reloadWithLangQuery(app)(languageCode)
}

const canonicalizeUrl = (app) => (url, opts = { showPageQuery: false }) => canonicalizeUrlHelper(url, app.i18n.countryCode, opts)

export default function ({ app, req, query }) {
  const countryCode = getCountryCode(app, req, query)
  const languageCode = getLanguageCode(app, countryCode, query)
  const languages = availableLanguages(countryCode)
  const messages = getLocaleMessagesFor(languages, countryCode)

  app.i18n = new VueI18n({
    locale: languageCode,
    fallbackLocale: ['en'],
    messages
  })
  app.i18n.countryCode = countryCode
  app.i18n.languages = languages
  app.i18n.switchLanguage = switchLanguage(app)
  app.i18n.setLanguagePreference = setLanguagePreference(app)
  app.i18n.acceptLanguage = `${languageCode}-${countryCode}`
  app.i18n.canonicalizeUrl = canonicalizeUrl(app)
  // This is exposed for tests (specs)
  app.i18n.reloadWithLangQuery = reloadWithLangQuery(app)

  switchCountry(app, countryCode)
  switchLanguage(app)(languageCode)
}
