<template>
  <NavItem :children="saleData.children" :boundary="boundary">
    <SmartLink :native="false" :to="saleData.url" @click="preventDefault">
      <NavTitle :is-presentation="true" :text="saleData.text" />
    </SmartLink>
    <template slot="content">
      <NavPane>
        <NavMenu>
          <NavMenuHeader :text="saleData.text" :url="saleData.url" />
          <NavMenuItem
            v-for="menu in saleData.children"
            :key="menu.text"
            :text="menu.text"
            :url="menu.url"
          >
            <template #default="slotProps">
              <SmartLink :native="false" :to="menu.url">
                {{ slotProps.text }}
              </SmartLink>
            </template>
          </NavMenuItem>
        </NavMenu>
      </NavPane>
    </template>
  </NavItem>
</template>

<script>
import NavItem from '@sephora-asia/core-nav-item/NavItem.vue'
import NavMenu from '@sephora-asia/core-nav-menu/NavMenu.vue'
import NavMenuHeader from '@sephora-asia/core-nav-menu-header/NavMenuHeader.vue'
import NavMenuItem from '@sephora-asia/core-nav-menu-item/NavMenuItem.vue'
import NavPane from '@sephora-asia/core-nav-pane/NavPane.vue'
import NavTitle from '@sephora-asia/core-nav-title/NavTitle.vue'

export default {
  name: 'NavBarSale',
  components: {
    NavItem,
    NavMenu,
    NavMenuHeader,
    NavMenuItem,
    NavPane,
    NavTitle
  },

  props: {
    saleData: {
      type: Object,
      default: () => {}
    },
    boundary: {
      type: String,
      default: ''
    }
  },

  methods: {
    preventDefault(e) {
      if (this.$device.isTablet) {
        e.preventDefault()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.level-item {
  display: contents;
}
</style>
