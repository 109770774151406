var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-results-container"},[_c('SearchResults',{staticClass:"pb-0",on:{"touchmove":_vm.emitScrollEvent},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.showPopularQueries)?_c('SearchResultsItem',{staticClass:"popular-searches",attrs:{"append-query":true,"is-inline":!_vm.$device.isMobile,"items":_vm.popularQueries,"title":_vm.$t('layout.searchResult.popularQueries'),"query-text":_vm.queryText,"item-type":"brand"},on:{"save-recent-search":_vm.saveRecentSearch,"toggle-active":_vm.toggleActive,"track-clicked-item":function (searchResultSlug) { return _vm.trackClickedItem(
              'search_menu_popular_results_click_ssr',
              undefined,
              searchResultSlug
            ); }}}):_vm._e(),_vm._v(" "),(_vm.showRecentSearches)?_c('SearchResultsItem',{attrs:{"items":_vm.recentSearches,"show-clear-button":true,"title":_vm.$t('layout.searchResult.recentSearches')},on:{"clear-search":function($event){return _vm.clearSearch('recentSearches')},"save-recent-search":_vm.saveRecentSearch,"toggle-active":_vm.toggleActive}}):_vm._e(),_vm._v(" "),(_vm.showRecentProducts)?_c('SearchResultsProduct',{attrs:{"products":_vm.recentProducts,"show-clear-button":true,"title":_vm.$t('layout.searchResult.recentlyViewedProducts'),"query-text":_vm.queryText},on:{"clear-search":function($event){return _vm.clearSearch('recentProducts')},"save-recent-search":_vm.saveRecentSearch,"toggle-active":_vm.toggleActive}}):_vm._e(),_vm._v(" "),_c('Loader',{staticClass:"loader-container pb-0",attrs:{"loading":_vm.loading}},[(_vm.showSuggestedBrands)?_c('SearchResultsItem',{staticClass:"suggested-brands",attrs:{"append-query":true,"highlight-text":true,"items":_vm.suggestedBrands,"title":_vm.$t('layout.searchResult.suggestedBrands'),"item-type":"brand","query-text":_vm.queryText},on:{"save-recent-search":_vm.saveRecentSearch,"toggle-active":_vm.toggleActive,"set-search-query-id-cookie":_vm.setSearchQueryIdCookie,"track-clicked-item":function (searchResultSlug) { return _vm.trackClickedItem(
                'search_menu_suggested_result_click_ssr',
                'brand',
                searchResultSlug
              ); }}}):_vm._e(),_vm._v(" "),(_vm.showSuggestedCategories)?_c('SearchResultsItem',{staticClass:"suggested-categories",attrs:{"append-query":true,"highlight-text":true,"items":_vm.suggestedCategories,"title":_vm.$t('layout.searchResult.suggestedCategories'),"query-text":_vm.queryText,"item-type":"category"},on:{"save-recent-search":_vm.saveRecentSearch,"toggle-active":_vm.toggleActive,"set-search-query-id-cookie":_vm.setSearchQueryIdCookie,"track-clicked-item":function (searchResultSlug) { return _vm.trackClickedItem(
                'search_menu_suggested_result_click_ssr',
                'category',
                searchResultSlug
              ); }}}):_vm._e(),_vm._v(" "),(_vm.showSuggestedProducts)?_c('SearchResultsProduct',{staticClass:"suggested-products",attrs:{"products":_vm.suggestedProducts,"title":_vm.$t('layout.searchResult.suggestedProducts'),"query-text":_vm.queryText},on:{"save-recent-search":_vm.saveRecentSearch,"toggle-active":_vm.toggleActive,"set-search-query-id-cookie":_vm.setSearchQueryIdCookie,"track-clicked-item":function (searchResultSlug) { return _vm.trackClickedItem(
                'search_menu_suggested_result_click_ssr',
                'product',
                searchResultSlug
              ); }}}):_vm._e(),_vm._v(" "),(_vm.showNoResults)?[_c('p',{staticClass:"no-results-text"},[_vm._v("\n            "+_vm._s(_vm.$t('layout.searchResult.noResult'))+"\n          ")]),_vm._v(" "),_c('ul',{staticClass:"search-tips"},[_c('li',[_vm._v(_vm._s(_vm.$t('layout.searchResult.spellingTip')))]),_vm._v(" "),_c('li',[_vm._v(_vm._s(_vm.$t('layout.searchResult.termsTip')))])])]:_vm._e()],2)]},proxy:true},(_vm.showViewAllButton)?{key:"view-results-button",fn:function(){return [_c('div',{staticClass:"button-container"},[_c('SmartLink',{attrs:{"to":_vm.slugWithQuery('/search')}},[_c('Button',{attrs:{"label":_vm.$t('layout.searchResult.viewAllResultButton'),"size":"is-fullwidth"},on:{"click":_vm.searchButtonClicked}})],1)],1)]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }