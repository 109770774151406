import ProductsTransformer from '../../api/ProductsTransformer.js'

export default () => ({
  namespaced: true,

  state: () => ({
    products: [],
    meta: {}
  }),

  mutations: {
    setListingPageProducts(state, products) {
      state.products = products
    },
    setListingPageProductsMeta(state, meta) {
      state.meta = meta
    }
  },

  getters: {
    products(state) {
      return state.products
    },
    meta(state) {
      return state.meta
    }
  },

  actions: {
    clear({ commit }) {
      commit('setListingPageProducts', [])
      commit('setListingPageProductsMeta', {})
    },
    async fetchProducts({ commit, dispatch, rootGetters }, params) {
      // clear the product and meta
      // (for the case where product exists before fetching new one)
      dispatch('clear')

      const showEarlyAccessPlp = await rootGetters[
        'globalConfig/showEarlyAccessPlp'
      ]

      const productsRepository = showEarlyAccessPlp
        ? this.$repositories.productsWithEarlyAccess
        : this.$repositories.products

      const { data, meta, included } =
        (await productsRepository.index({
          ...params,
          include: 'featured_variant,brand,featured_ad'
        })) || {}

      if (!data || !meta || !included) return

      const productsTransformer = new ProductsTransformer(data, included, meta)

      commit('setListingPageProducts', productsTransformer.transformedData)
      commit('setListingPageProductsMeta', productsTransformer.transformedMeta)
    }
  }
})
