import CategoriesTransformer from '../../api/CategoriesTransformer.js'

export default () => ({
  namespaced: true,
  state: () => ({
    categories: []
  }),

  mutations: {
    setCategories(state, categories) {
      state.categories = categories
    }
  },

  getters: {
    categories(state) {
      return state.categories
    }
  },

  actions: {
    async fetchAll({ commit }) {
      const { data } = (await this.$repositories.categories.index()) || {}
      if (data) {
        commit('setCategories', new CategoriesTransformer(data).transformedData)
      }
    }
  }
})
