import { render, staticRenderFns } from "./NavBarCategories.vue?vue&type=template&id=79d0fa6a&scoped=true&"
import script from "./NavBarCategories.vue?vue&type=script&lang=js&"
export * from "./NavBarCategories.vue?vue&type=script&lang=js&"
import style0 from "./NavBarCategories.vue?vue&type=style&index=0&id=79d0fa6a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d0fa6a",
  null
  
)

export default component.exports