import Vue from 'vue'
import menuItemFormatterMixin from '~/mixins/menuItemFormatterMixin.js'

const SidebarNavMenuServicesDataTransformer = (component) => {
  return Vue.component('SidebarNavMenuServicesDataTransformer', {
    mixins: [menuItemFormatterMixin],

    props: {
      links: {
        type: Array,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      iconSrc: {
        type: String,
        required: true
      }
    },

    computed: {
      menuItemData() {
        const formattedLinks = this.links.map((link) =>
          this.convertToMenuItemFormat(link.label, link.path, {
            description: link.description,
            hasSeparator: false,
            isNonNativeLink: true
          })
        )

        return [
          this.convertToMenuItemFormat(this.title, '', {
            hasSeparator: false,
            children: formattedLinks,
            iconSrc: this.iconSrc
          })
        ]
      }
    },

    render(createElement, _) {
      return createElement(component, {
        props: {
          menuItemData: this.menuItemData
        }
      })
    }
  })
}

export default SidebarNavMenuServicesDataTransformer
