<template>
  <div>
    <div v-if="!userLoggedIn" class="columns is-mobile is-marginless">
      <div class="column is-paddingless text-center register">
        <SidebarNavItem
          :text="$t('dictionary.register')"
          :level="1"
          url="/sign_in"
          :has-separator="true"
          data-analytics-id="nav-link"
          :data-analytics-label="$t('dictionary.register')"
        />
      </div>
      <div class="column is-paddingless text-center sign-in">
        <SidebarNavItem
          :text="$t('dictionary.signIn')"
          :level="1"
          url="/sign_in"
          :has-separator="true"
          data-analytics-id="nav-link"
          :data-analytics-label="$t('dictionary.signIn')"
        />
      </div>
    </div>
    <SidebarNavMenuItems v-else :menu-item-data="menuItemData" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarNavItem from '@sephora-asia/core-sidebar-nav-item/SidebarNavItem.vue'
import menuItemFormatterMixin from '~/mixins/menuItemFormatterMixin.js'
import SidebarNavMenuItems from '~/components/SidebarNavMenuItems.vue'

export default {
  name: 'SidebarNavMenuMyAccount',

  components: {
    SidebarNavItem,
    SidebarNavMenuItems
  },

  mixins: [menuItemFormatterMixin],

  computed: {
    ...mapGetters({
      userLoggedIn: 'user/loggedIn',
      enableFullsize: 'globalConfig/enableFullsize',
      enableBeautyProfile: 'globalConfig/enableBeautyProfile'
    }),

    menuItemData() {
      const accountLinks = [
        this.convertToMenuItemFormat(
          this.$t('dictionary.dashboard'),
          '/account/dashboard',
          { hasSeparator: true, isNonNativeLink: true }
        ),
        this.convertToMenuItemFormat(
          this.$t('dictionary.orders'),
          '/account/orders',
          { hasSeparator: true, isNonNativeLink: true }
        ),
        this.convertToMenuItemFormat(
          this.enableFullsize
            ? this.$t('dictionary.getItAgain')
            : this.$t('dictionary.purchaseAgain'),
          this.enableFullsize
            ? '/account/get-it-again'
            : '/account/purchased-items',
          { hasSeparator: true, isNonNativeLink: true }
        ),
        this.convertToMenuItemFormat(
          this.$t('dictionary.reviews'),
          '/account/reviews',
          { hasSeparator: true, isNonNativeLink: true }
        ),
        this.convertToMenuItemFormat(
          this.$t('dictionary.wishlist'),
          '/wishlist',
          { hasSeparator: true, isNonNativeLink: true }
        ),
        this.convertToMenuItemFormat(
          this.$t('dictionary.waitlist'),
          '/waitlists',
          { hasSeparator: true, isNonNativeLink: true }
        ),
        ...(this.enableBeautyProfile ? this.beautyProfileLink() : [])
      ]

      return [
        this.convertToMenuItemFormat(
          this.$t('layout.navigation.myAccount'),
          '',
          { hasSeparator: true, children: accountLinks }
        )
      ]
    }
  },

  methods: {
    beautyProfileLink() {
      return [
        this.convertToMenuItemFormat(
          this.$t('dictionary.beautyProfile'),
          '/account/beauty-profile',
          { hasSeparator: true, isNonNativeLink: true }
        )
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.register {
  border-right: 1px solid $grey;
  text-align: center;
}
.sign-in {
  text-align: center;
}
</style>
