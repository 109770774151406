export default class BrandsRecommendationsTransformer {
  constructor(products, included) {
    this.products = products
    this.variants = included?.filter(({ type }) => type === 'variants')
  }

  get transformedData() {
    return this.products.reduce((productsArray, product) => {
      const featuredVariant = this.variants.find(
        ({ id }) => id === product.relationships['featured-variant'].data?.id
      )

      if (featuredVariant) {
        productsArray.push({
          id: product.id,
          brandName: product.attributes['brand-name'] || '',
          productName: product.attributes.name,
          rating: product.attributes.rating || 0,
          pdpUrl: `/products/${product.attributes['slug-url']}`,
          featuredVariant: {
            id: featuredVariant.id,
            slugUrl: featuredVariant.attributes['slug-url'],
            name: featuredVariant.attributes.name,
            originalPrice: featuredVariant.attributes['display-original-price'],
            price: featuredVariant.attributes['display-price'],
            saleText: featuredVariant.attributes['sale-text'],
            onSale: featuredVariant.attributes['under-sale'],
            imageUrl: featuredVariant.attributes['image-url'],
            sapSku: featuredVariant.attributes['sap-sku'],
            soldOut: featuredVariant.attributes['sold-out'],
            tags: featuredVariant.attributes.tags
          }
        })
      }
      return productsArray
    }, [])
  }
}
