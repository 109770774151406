export default () => ({
  namespaced: true,

  state: () => ({
    wishlistedVariantIds: []
  }),

  mutations: {
    setWishlistedVariantIds(state, wishlistedVariantIds) {
      state.wishlistedVariantIds = wishlistedVariantIds
    }
  },

  getters: {
    wishlistedVariantIds(state) {
      return state.wishlistedVariantIds
    }
  },

  actions: {
    async fetchWishlist({ commit, state, rootGetters }) {
      // This getter is referenced from common-ui user store
      // might need to refactor for microfrontend structure as it's tightly coupled atm.
      if (!rootGetters['user/loggedIn']) return
      if (state.wishlistedVariantIds.length !== 0) return
      let wishlistedVariantIds

      // Check localStorage
      try {
        wishlistedVariantIds =
          JSON.parse(globalThis.localStorage?.getItem('wishlistedVariants')) ||
          []
      } catch {
        wishlistedVariantIds = []
      }

      if (wishlistedVariantIds.length !== 0) {
        commit('setWishlistedVariantIds', wishlistedVariantIds)
        return
      }

      // Call API
      const { data } =
        (await this.$repositories.wishlists.index().catch((e) => {})) || {}

      if (!data) return

      wishlistedVariantIds = data.relationships.variants.data.map((variant) =>
        Number(variant.id)
      )

      globalThis.localStorage?.setItem(
        'wishlistedVariants',
        JSON.stringify(wishlistedVariantIds)
      )

      commit('setWishlistedVariantIds', wishlistedVariantIds)
    },

    async addToWishlist({ commit, rootGetters }, id) {
      // This getter is referenced from common-ui user store
      // might need to refactor for microfrontend structure as it's tightly coupled atm.
      if (!rootGetters['user/loggedIn']) return

      const { data } =
        (await this.$repositories.wishlistVariants
          .put({
            id
          })
          .catch((e) => {})) || {}

      if (!data) return

      const wishlistedVariantIds = data.relationships.variants.data.map(
        (variant) => Number(variant.id)
      )

      globalThis.localStorage?.setItem(
        'wishlistedVariants',
        JSON.stringify(wishlistedVariantIds)
      )

      commit('setWishlistedVariantIds', wishlistedVariantIds)
    },

    clearWishlist({ commit }) {
      globalThis.localStorage?.removeItem('wishlistedVariants')
      commit('setWishlistedVariantIds', [])
    }
  }
})
