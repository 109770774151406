import FeatureCarouselTransformer from '../../api/FeatureCarouselTransformer.js'

export default () => ({
  namespaced: true,

  state: () => ({
    featureCarousel: null
  }),

  mutations: {
    setFeatureCarousel(state, object) {
      state.featureCarousel = object
    }
  },

  getters: {
    featureCarousel(state) {
      return state.featureCarousel
    }
  },

  actions: {
    async fetchLive({ commit }) {
      const { data, included } = await this.$repositories.featureCarousel
        .get({
          include: 'feature_carousel_items'
        })
        .catch((e) => {})

      commit(
        'setFeatureCarousel',
        data
          ? new FeatureCarouselTransformer(data, included).transformedData
          : null
      )
    }
  }
})
