import startCase from 'lodash/startCase'

export default class CategoriesTransformer {
  constructor(categories) {
    this.categories = categories
  }

  get transformedData() {
    return this.categories.map((category) => {
      return {
        id: category.id,
        text: category.attributes.label,
        link: category.attributes['slug-url'],
        parentId: category.attributes['parent-id'],
        dataAnalytics: {
          label: this.dataAnalyticsLabelFor(category),
          viewAllLabel: this.dataAnalyticsViewAllLabelFor(category)
        }
      }
    })
  }

  dataAnalyticsLabelFor(category) {
    const level = category.attributes.level
    const children = category.relationships.children.data

    if (level === 1 && children.length > 0) {
      // this is for level 1 with children
      // makeup > Makeup
      const label = category.attributes['slug-url'].split('/')
      return `${label[0]} > ${label[0].toUpperCase()}`
    } else if (level === 2 && children.length > 0) {
      // this is for level 2 with children
      // skincare > Cleanser & Exfoliator
      const label = category.attributes['slug-url'].split('/')
      return `${label[0]} > ${startCase(label[1])}`
    } else {
      // this is for level 1, level 2 without children, and level 3
      // skincare
      // skincare > toner
      // skincare > cleanser-and-exfoliator > facial-cleanser
      return category.attributes['slug-url'].split('/').join(' > ')
    }
  }

  dataAnalyticsViewAllLabelFor(category) {
    const level = category.attributes.level
    const children = category.relationships.children.data

    if (level === 1) {
      // tools-and-brushes > View All Tools & Brushes
      return `${category.attributes['slug-url']} > View All ${startCase(
        category.attributes.label
      )}`
    } else if (level === 2 && children.length > 0) {
      // skincare > cleanser-and-exfoliator
      return category.attributes['slug-url'].split('/').join(' > ')
    } else {
      return ''
    }
  }
}
