import Vue from 'vue'
import { search, disassemble } from 'hangul-js'

const BRANDS_LINK = '/brands'

class Alphabet {
  constructor(brands) {
    this.brands = brands
    this.alphabets = Array.from(Array(26)).map((e, i) =>
      String.fromCharCode(i + 97)
    )
  }

  group() {
    const brandsByLetters = {}

    this.alphabets.forEach((letter) => {
      brandsByLetters[letter] = this.brands.filter(
        (brand) => brand.label.charAt(0).toLowerCase() === letter
      )
    })

    return brandsByLetters
  }

  firstLetter() {
    return this.alphabets[0]
  }
}

class Hangul {
  constructor(brands) {
    this.brands = brands
    this.hangul = [
      '가',
      '까',
      '나',
      '다',
      '따',
      '라',
      '마',
      '바',
      '빠',
      '사',
      '싸',
      '아',
      '자',
      '짜',
      '차',
      '카',
      '타',
      '파',
      '하',
      '#' // used for non korean brand names
    ]
  }

  group() {
    const brandsByLetters = {}

    this.hangul.forEach((letter) => {
      brandsByLetters[letter] = this.brands.filter((brand) => {
        const char = disassemble(brand.label)[0]

        if (search(letter, char) > -1) {
          return true
        }

        if (letter === '#' && !this.isKorean(char)) {
          // To handle non korean brands
          // ex. '369 가방', 'sephora'
          return true
        }

        return false
      })
    })

    return brandsByLetters
  }

  firstLetter() {
    return this.hangul[0]
  }

  isKorean(char) {
    return [
      'ㄱ',
      'ㄲ',
      'ㄴ',
      'ㄷ',
      'ㄸ',
      'ㄹ',
      'ㅁ',
      'ㅂ',
      'ㅃ',
      'ㅅ',
      'ㅆ',
      'ㅇ',
      'ㅈ',
      'ㅉ',
      'ㅊ',
      'ㅋ',
      'ㅌ',
      'ㅍ',
      'ㅎ'
    ].includes(char)
  }
}

const brandGroups = (brands, locale) => {
  const Klass =
    {
      ko: new Hangul(brands)
    }[locale] || new Alphabet(brands)

  const groups = Klass.group()
  const firstLetter = Klass.firstLetter()

  // Add brands starting with number to first letter
  const brandsStartingWithNumber = brands.filter(
    (brand) => !isNaN(parseInt(brand.label.charAt(0)))
  )

  groups[firstLetter] = brandsStartingWithNumber.concat(groups[firstLetter])

  Object.keys(groups).forEach((key) => {
    const brands = groups[key]
    brands.sort((a, b) => a.label.localeCompare(b.label))
  })

  return groups
}

const NavBarBrandsDataTransformer = (component) => {
  return Vue.component('NavBarBrandsDataTransformer', {
    props: {
      brands: {
        type: Array,
        required: true
      },
      boundary: {
        type: String,
        default: ''
      }
    },

    computed: {
      brandsData() {
        return brandGroups(this.brands, this.$i18n.locale)
      }
    },

    methods: {
      navBrandsTitle() {
        return {
          text: this.$t('layout.navBar.brands'),
          url: BRANDS_LINK,
          children: []
        }
      }
    },

    render(createElement, _) {
      return createElement(component, {
        props: {
          navBrandsTitle: this.navBrandsTitle(),
          brandsData: this.brandsData,
          boundary: this.boundary
        }
      })
    }
  })
}

export default NavBarBrandsDataTransformer
