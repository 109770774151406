<template>
  <Dropdown class="get-app-dropdown">
    <TopNavigationElement
      :text="$t('layout.getApp.navigationHeader')"
      :icon-src="$options.locals.getAppIcon"
      :additional-attrs="additionalAttrs"
    />
    <template slot="content">
      <div class="get-app-content">
        <GetAppLinks
          :title="$t('layout.getApp.headerTitle')"
          :get-via-text="$t('dictionary.or')"
        />
        <div class="get-app-benefits">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="get-app-benefits-text"
            v-html="getAppDesktopDropdown.text"
          />
          <!-- eslint-enable -->
          <SmartLink
            :native="false"
            to="/pages/mobile-app"
            class="get-app-link"
          >
            <span>{{ $t('layout.getApp.benefits') }}</span>
            <span class="arrow-small" />
          </SmartLink>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import Dropdown from '@sephora-asia/core-dropdown/Dropdown.vue'
import TopNavigationElement from '@sephora-asia/core-top-navigation-element/TopNavigationElement.vue'
import GetAppLinks from '~/components/GetAppLinks.vue'
import GetAppIcon from '~/assets/images/get-app.svg'

export default {
  name: 'TopNavigationDropdownGetApp',

  components: {
    Dropdown,
    TopNavigationElement,
    GetAppLinks
  },

  locals: {
    getAppIcon: GetAppIcon
  },

  computed: {
    ...mapGetters({
      getAppDesktopDropdown: 'globalConfig/getAppDesktopDropdown'
    }),
    additionalAttrs() {
      return {
        'data-analytics-id': 'header-click',
        'data-analytics-label': 'get_app'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.get-app-dropdown {
  margin-right: 20px;
}

/deep/ .get-app-content {
  color: $black;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  width: 320px;

  .title {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .get-app-qr-code-icon {
    height: 150px;
    padding-bottom: 10px;
  }

  .get-app-via {
    margin-top: 20px;
    padding-bottom: 10px;
  }

  .get-app-icons {
    padding-bottom: 20px;
  }

  .get-app-android-icon {
    height: 59px;
    vertical-align: top;
    margin-right: -10px;
  }

  .get-app-ios-icon {
    margin-top: 10px;
  }

  .get-app-benefits {
    text-align: left;
    background-color: $grey-light;
    margin-bottom: -10px;
    padding: 20px 30px;
    color: $grey-darker;
  }

  .get-app-link {
    font-weight: 300;
    font-size: 13px;
    color: $grey-darker;
    display: block;
    padding-top: 10px;
    width: 160px;
  }

  .arrow-small {
    width: 10px;
    height: 10px;
    display: inline-block;
    background-image: url('~assets/images/arrow-small.svg');
    background-size: contain;
    margin-left: 5px;
  }
}
</style>
