<template>
  <div class="header-banner-container">
    <HeaderBanner
      v-if="canRenderBanner"
      :img-src="imageUrl"
      :link="linkUrl"
      :open-in-new-tab="newTab"
    />
    <SignInBanner
      v-if="!isUserLoggedIn"
      data-analytics-id="homepage-container"
      data-analytics-label="Top Banner"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HeaderBanner from '~/components/HeaderBanner.vue'
import SignInBanner from '~/components/SignInBanner.vue'

export default {
  name: 'HeaderBannerContainer',

  components: {
    HeaderBanner,
    SignInBanner
  },

  computed: {
    ...mapGetters({
      linkUrl: 'layoutConfig/headerBannerUrl',
      imageUrl: 'layoutConfig/headerBannerImageUrl',
      newTab: 'layoutConfig/headerBannerNewTab',
      validTill: 'layoutConfig/headerBannerValidTill',
      isUserLoggedIn: 'user/loggedIn'
    }),

    isBannerValid() {
      if (!this.imageUrl) {
        return false
      }
      if (this.validTill == null) {
        return true
      }

      const now = new Date()
      const validTill = new Date(this.validTill)
      return validTill >= now
    },

    canRenderBanner() {
      return this.isBannerValid && this.isUserLoggedIn
    }
  }
}
</script>

<style lang="scss" scoped>
.header-banner-container {
  @include mobile {
    margin: 0;
  }
}
</style>
