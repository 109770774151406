import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'

export default class SearchPopularQueriesTransformer {
  constructor(popularQueries) {
    this.popularQueries = popularQueries
  }

  get transformedData() {
    return this.popularQueries.map((query) => {
      return {
        id: query.id,
        name: startCase(toLower(query.attributes.keyword)),
        slug: '/brands/' + query.attributes['slug-url'],
        type: 'brands'
      }
    })
  }
}
