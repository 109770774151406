<template>
  <div class="categories-container" @mouseover="resetMenu">
    <NavItem
      v-for="navItem in categoryData"
      :key="navItem.url"
      :children="navItem.children"
      :boundary="boundary"
      :is-hover="!isClicked"
    >
      <SmartLink
        :data-analytics-label="navItem.text"
        :data-analytics-path="getFormattedPath(navItem.url)"
        :native="isCategoryPage(navItem)"
        :to="navItem.url"
        data-analytics-id="top-menu"
        @mouseover="
          trackTopMenuHover('top_menu_hovered', getFormattedPath(navItem.url))
        "
        @click="
          preventDefault(navItem, $event)
          closeMenu(navItem)
        "
      >
        <NavTitle :text="navItem.text" :is-presentation="true" />
      </SmartLink>
      <template slot="content">
        <NavPane v-if="!isClicked">
          <NavMenu v-for="menu in navItem.children" :key="menu.text">
            <NavMenuHeader
              v-if="menu.url !== '/gift-cards'"
              :anchor-attrs="subMenuAnchorAttrs(menu.url)"
              :text="menu.text"
              :url="menu.url"
              @click="closeMenu"
            >
              <template #default="slotProps">
                <SmartLink :native="isCategoryPage(menu)" :to="menu.url">
                  {{ slotProps.text }}
                </SmartLink>
              </template>
            </NavMenuHeader>

            <NavMenuHeader
              v-else
              :text="menu.text"
              :url="menu.url"
              :open-in-new-tab="true"
              :anchor-attrs="subMenuAnchorAttrs(menu.url)"
            />

            <NavMenuItem
              v-for="submenu in menu.children"
              :key="submenu.text"
              :anchor-attrs="subMenuAnchorAttrs(submenu.url)"
              :text="submenu.text"
              :url="submenu.url"
              @click="closeMenu"
            >
              <template #default="slotProps">
                <SmartLink :native="isCategoryPage(submenu)" :to="submenu.url">
                  {{ slotProps.text }}
                </SmartLink>
              </template>
            </NavMenuItem>
          </NavMenu>
        </NavPane>
      </template>
    </NavItem>
  </div>
</template>

<script>
import NavItem from '@sephora-asia/core-nav-item/NavItem.vue'
import NavMenu from '@sephora-asia/core-nav-menu/NavMenu.vue'
import NavMenuHeader from '@sephora-asia/core-nav-menu-header/NavMenuHeader.vue'
import NavMenuItem from '@sephora-asia/core-nav-menu-item/NavMenuItem.vue'
import NavPane from '@sephora-asia/core-nav-pane/NavPane.vue'
import NavTitle from '@sephora-asia/core-nav-title/NavTitle.vue'
import dataAnalyticsMixin from '~/mixins/dataAnalyticsMixin.js'

export default {
  name: 'NavBarCategories',

  components: {
    NavItem,
    NavMenu,
    NavMenuHeader,
    NavMenuItem,
    NavPane,
    NavTitle
  },

  mixins: [dataAnalyticsMixin],

  props: {
    categoryData: {
      type: Array,
      default: () => []
    },
    boundary: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isClicked: false
    }
  },

  methods: {
    preventDefault(navItem, e) {
      if (this.$device.isTablet && navItem.url !== '/new-arrivals') {
        e.preventDefault()
      }
    },
    subMenuAnchorAttrs(url) {
      return {
        'data-analytics-id': 'top-sub-menu',
        'data-analytics-path': this.getFormattedPath(url)
      }
    },
    closeMenu(navItem) {
      if (navItem.url === '/categories/') return
      this.isClicked = true
    },
    resetMenu() {
      this.isClicked = false
    },
    isCategoryPage(navItem) {
      return (
        navItem.url.includes('categories') && navItem.url !== '/categories/'
      )
    }
  }
}
</script>

<style scoped>
.categories-container {
  display: contents;
}
</style>
