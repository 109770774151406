<template>
  <!-- This is a temporary solution to handle tablet view, track https://sephora-asia.atlassian.net/browse/SHOP-1240 for improvement -->
  <div class="nav-container">
    <Nav>
      <!-- Long navigation for desktop -->
      <NavBarCategoriesContainer
        :categories="categories"
        :boundary="boundary"
        class="is-hidden-touch is-hidden-desktop-only"
      />
      <!-- Short navigation for tablet -->
      <NavBarCategoriesContainer
        :categories="categories"
        :boundary="boundary"
        :has-single-category-root="true"
        class="is-hidden-widescreen"
      />
      <NavBarBrandsContainer :brands="brands" :boundary="boundary" />
      <NavBarSaleContainer :categories="categories" :boundary="boundary" />
    </Nav>
  </div>
</template>

<script>
import Nav from '@sephora-asia/core-nav/Nav.vue'
import { mapGetters } from 'vuex'
import NavBarBrandsDataTransformer from '~/components/NavBarBrandsDataTransformer.js'
import NavBarBrands from '~/components/NavBarBrands.vue'
import NavBarCategoriesDataTransformer from '~/components/NavBarCategoriesDataTransformer.js'
import NavBarCategories from '~/components/NavBarCategories.vue'
import NavBarSale from '~/components/NavBarSale.vue'
import NavBarSaleDataTransformer from '~/components/NavBarSaleDataTransformer.js'

const NavBarBrandsContainer = NavBarBrandsDataTransformer(NavBarBrands)
const NavBarCategoriesContainer = NavBarCategoriesDataTransformer(
  NavBarCategories
)
const NavBarSaleContainer = NavBarSaleDataTransformer(NavBarSale)

export default {
  name: 'NavBarContainer',
  components: {
    Nav,
    NavBarBrandsContainer,
    NavBarCategoriesContainer,
    NavBarSaleContainer
  },

  data() {
    return {
      boundary: 'boundary'
    }
  },

  computed: {
    ...mapGetters({
      brands: 'brand/brands',
      categories: 'category/categories'
    })
  }
}
</script>

<style lang="scss">
.nav {
  display: none;

  @include tablet {
    display: block;
  }
}
</style>

<style lang="scss" scoped>
.nav-container {
  display: flex;
  justify-content: center;
}
</style>
