<template>
  <div class="search-bar-container">
    <transition name="fade">
      <div
        v-if="isActive"
        class="search-bar-overlay"
        @click="toggleActive(false)"
      />
    </transition>
    <SearchBar
      :aria-label="$t('layout.searchBar.ariaLabel')"
      :class="{ active: isActive }"
      :is-active="isActive"
      :is-scrolling="isScrolling"
      :placeholder-text="$t('layout.searchBar.placeholderText')"
      :initial-query="initialQuery"
      @changed="setQueryText"
      @has-focus="toggleActive"
      @submitted="goToSearchPage"
    />
    <transition name="fade">
      <SearchResultsContainer
        v-if="isActive"
        :is-scrolling="isScrolling"
        :loading="loading"
        :popular-queries="popularQueries"
        :suggested-brands="suggestedBrands"
        :suggested-categories="suggestedCategories"
        :suggested-products="suggestedProducts"
        :query-text="queryText"
        @toggle-active="toggleActive"
        @scrolling-search-result-container="isScrolling = true"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import SearchBar from '@sephora-asia/core-search-bar/SearchBar.vue'
import SearchResultsContainer from '~/components/SearchResultsContainer.vue'
import searchMixin from '~/mixins/searchMixin.js'

export default {
  name: 'SearchBarContainer',

  components: {
    SearchBar,
    SearchResultsContainer
  },

  mixins: [searchMixin],

  data() {
    return {
      isActive: false,
      isScrolling: false,
      isSearching: false,
      loading: false,
      queryText: ''
    }
  },

  computed: {
    ...mapGetters({
      popularQueries: 'search/popularQueries',
      suggestedBrands: 'search/suggestedBrands',
      suggestedCategories: 'search/suggestedCategories',
      suggestedProducts: 'search/suggestedProducts'
    }),
    initialQuery() {
      if (this.$route.path.includes('/search') && this.$route.query.q) {
        return this.$route.query.q
      }

      return ''
    }
  },

  methods: {
    debounceQuickSearch: debounce(async function () {
      this.$analytics.trackEvent('search_menu_start_input_filling_ssr')
      await this.fetchSearchResults()
    }, 250),
    fetchSearchResults() {
      return this.$store
        .dispatch('search/fetchAll', this.queryText)
        .then(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    trackSearchEvent() {
      const data = {
        params: {
          search_term: this.queryText
        }
      }
      this.$analytics.trackEvent('search_menu_submit_click_ssr', data)
    },
    goToSearchPage() {
      // close search bar and redirect to search page
      if (this.queryText.length) {
        this.trackSearchEvent()
        this.saveRecentSearch({
          name: this.queryText,
          slug: this.slugWithQuery('/search')
        })
        this.isActive = false
        this.$router.push(`/search?q=${encodeURI(this.queryText)}`)
      }
    },
    toggleActive(isActive) {
      if (this.initialQuery) {
        this.setQueryText({ query: this.initialQuery })
      }
      if (this.popularQueries.length === 0 && this.isActive) {
        this.$store.dispatch('search/fetchPopularQueries')
      }
      // reset the isScrolling back to false when the user toggle active the search input
      this.isScrolling = false
      this.isActive = isActive
      this.$emit('focused', isActive)
    },
    setQueryText(query) {
      // prevent extra empty space added in front or back of current
      // query text that triggers the unnecessary search
      if (this.queryText === query.query.trim()) {
        return
      }

      // set trimmed queryText
      this.queryText = query.query.trim()
      // debounce for 250ms and start calling search API
      // only if queryText more than one characters
      if (this.queryText.length > 2) {
        this.loading = true
        this.debounceQuickSearch()
      } else {
        this.$store.commit('search/setSuggestedBrands', [])
        this.$store.commit('search/setSuggestedCategories', [])
        this.$store.commit('search/setSuggestedProducts', [])
        this.$store.commit('search/setSearchQueryId', {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-bar-container {
  /deep/ .search-bar {
    position: relative;

    &.active {
      z-index: $zindex-modal;
    }
  }

  .search-bar-overlay {
    background-color: hsla(0, 0%, 100%, 0.5);
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zindex-modal-backdrop;
  }

  @include desktop {
    margin-right: 15px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
