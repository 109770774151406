export default class ShopByCampaignImagesTransformer {
  constructor(images) {
    this.images = images
  }

  get transformedData() {
    return this.images.map((image) => {
      return {
        id: image.id,
        title: image.attributes.title,
        url: image.attributes.url,
        imageUrl: image.attributes['image-url']
      }
    })
  }
}
