<template>
  <div v-if="organization.displayInFooter || false" class="footer-legal-info">
    <h4 class="header">{{ organization.companyName }}</h4>
    <div class="content">
      <p>
        {{ organization.streetAddress }}
        <span class="is-hidden-mobile"> | </span>
        <br class="is-hidden-tablet" />
        {{ ceoName }}
      </p>
      <p>
        {{ businessRegistrationNumber }}
        <span class="is-hidden-mobile"> | </span>
        <br class="is-hidden-tablet" />
        {{ ecommerceRegistrationNumber }}
      </p>
    </div>
    <SmartLink
      :native="false"
      :to="organization.businessInformationLink"
      class="footer-link"
    >
      {{ $t('layout.footer.businessInformation') }}
    </SmartLink>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FooterLegalInfo',
  computed: {
    ...mapGetters({
      organization: 'globalConfig/organization'
    }),
    ceoName() {
      return `${this.$t('layout.footer.representative')}: ${
        this.organization.ceoName || null
      }`
    },
    businessRegistrationNumber() {
      return `${this.$t('layout.footer.businessRegistrationNumber')}: ${
        this.organization.businessRegistrationNumber
      }`
    },
    ecommerceRegistrationNumber() {
      return `${this.$t('layout.footer.ecommerceRegistrationNumber')}: ${
        this.organization.ecommerceRegistrationNumber
      }`
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-legal-info {
  text-align: center;
  padding-top: 10px;
  font-size: 13px;

  .header {
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .content {
    margin-bottom: 5px;
  }

  .footer-link {
    display: block;
    text-decoration: underline;
  }
}
</style>
