export default class BasicModule {
  constructor(name, config = {}) {
    this.name = name
    this.config = config
  }

  trackEvent(eventName, params = {}) {
    /* Overriden by modules */
  }
}
