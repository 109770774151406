<template>
  <transition appear name="slide">
    <div class="legal-notice-container">
      <!-- eslint-disable vue/no-v-html -->
      <div class="notice-message" v-html="legalNotice" />
      <!--eslint-enable-->
      <Icons
        class="close-button-container"
        name="close"
        @click.native="closeToast"
      />
    </div>
  </transition>
</template>

<script>
import Icons from '@sephora-asia/atoms-icons/Icons.vue'

export default {
  name: 'LegalNotice',

  components: {
    Icons
  },

  props: {
    legalNotice: {
      type: String,
      default: ''
    }
  },

  methods: {
    closeToast() {
      // set 10 years expiry time
      const expiryTime = 3650 * 24 * 60 * 60
      this.$cookies.set('legal_notice', true, {
        maxAge: expiryTime,
        path: '/'
      })
      this.$emit('close-toast')
    }
  }
}
</script>

<style lang="scss" scoped>
.legal-notice-container {
  width: 100%;
  position: fixed;
  inset: unset 0 0;
  z-index: 999998;
  background: $grey-light;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notice-message {
  padding: 21px;
  text-align: center;

  @include mobile {
    text-align: left;
  }
}

.close-button-container {
  --icon-size: 20px;
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
}

.slide-leave-active,
.slide-enter-active {
  transition: 1s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translate(0, 100%);
}
</style>
