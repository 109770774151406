export default class LineItemTransformer {
  constructor(lineItems, variants) {
    this.lineItems = lineItems
    this.variants = variants
  }

  get transformedData() {
    const data = this.variants
      ? this.transformCartsLineItems()
      : this.transformBagLineItems()

    return data
  }

  // To transform line items from "/api/v2.5/cart/line_items" endpoint
  transformCartsLineItems() {
    return this.lineItems.reduce((lineItemsArray, lineItem) => {
      const lineItemType = lineItem.attributes['line-item-type']
      const variant = this.variants.find((v) => {
        return v.id.toString() === lineItem.attributes['variant-id'].toString()
      })

      if (variant) {
        const data = {
          id: lineItem.id,
          type: lineItemType,
          unitPrice: lineItem.attributes['display-price'],
          quantityValue: lineItem.attributes.quantity,
          brandName: variant.attributes['brand-name'],
          imgSrc: variant.attributes['cart-image-url'],
          productName: variant.attributes['product-name'],
          variantName: variant.attributes.name,
          variantId: variant.id,
          sapSku: variant.attributes['sap-sku']
        }

        if (lineItemType === 'product') {
          data.productId = lineItem.relationships.product.data.id
        }

        lineItemsArray.push(data)
      }
      return lineItemsArray
    }, [])
  }

  // To transform line items from "/api/v2.5/carts/<token>/bag" endpoint
  transformBagLineItems() {
    return this.lineItems.map((lineItem) => {
      const data = {
        id: lineItem.id,
        type: lineItem.type,
        unitPrice: lineItem['display-price'],
        quantityValue: lineItem.quantity,
        brandName: lineItem['brand-name'],
        imgSrc: lineItem['image-url'],
        productName: lineItem['product-name'],
        variantName: lineItem['variant-name'],
        variantId: lineItem['variant-id']
      }

      if (lineItem.type === 'product') {
        data.productId = lineItem['checkoutable-id']
      } else if (
        lineItem.type === 'gwp' ||
        lineItem.type === 'redeemable' ||
        lineItem.type === 'free_sample'
      ) {
        data.sampleId = lineItem['checkoutable-id']
      }

      return data
    })
  }
}
