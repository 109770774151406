import SearchTransformer from '../../api/SearchTransformer.js'

export default () => ({
  namespaced: true,

  state: () => ({
    query: '',
    sort: '',
    searchQueryId: {}
  }),

  mutations: {
    setQuery(state, string) {
      state.query = string
    },
    setSort(state, string) {
      state.sort = string
    },
    setSearchQueryId(state, searchQueryId) {
      state.searchQueryId = searchQueryId
    }
  },

  getters: {
    searchQueryId(state) {
      return state.searchQueryId
    }
  },

  actions: {
    async fetchProducts(
      { commit, state, rootGetters },
      { params, language, path }
    ) {
      commit('listingPageProducts/setListingPageProducts', [], {
        root: true
      })
      if (!params.query) return

      const requestParams = {
        ...params,
        'where[facets]':
          state.query === params.query && state.sort === params.sort
            ? 'false'
            : 'true'
      }

      const showEarlyAccessPlp = await rootGetters[
        'globalConfig/showEarlyAccessPlp'
      ]

      const searchRepository = showEarlyAccessPlp
        ? this.$repositories.searchWithEarlyAccess
        : this.$repositories.search

      const response = await searchRepository.index(requestParams)
      const searchTransformer = new SearchTransformer(
        response.results.variants,
        language
      )
      const {
        products,
        brands,
        categories,
        meta,
        minPrice,
        maxPrice,
        hasUnderSaleProducts
      } = searchTransformer.transformedData
      meta.currentPage = params['page[number]']

      if (response?.results?.variants?.queryID) {
        commit('setSearchQueryId', {
          value: response.results.variants.queryID,
          path
        })
      }

      commit('listingPageProducts/setListingPageProducts', products, {
        root: true
      })
      commit('listingPageProducts/setListingPageProductsMeta', meta, {
        root: true
      })
      if (state.query !== params.query || state.sort !== params.sort) {
        commit('listingPageFilters/setListingPageBrandFilters', brands, {
          root: true
        })
        commit('listingPageFilters/setListingPageCategoryFilters', categories, {
          root: true
        })
        if (hasUnderSaleProducts) {
          commit(
            'listingPageFilters/setListingPageOtherFilters',
            [{ name: 'onSale', slugUrl: 'on_sale' }],
            { root: true }
          )
        }
        commit(
          'listingPageFilters/setListingPagePriceRangeFilter',
          { minPrice, maxPrice },
          { root: true }
        )
        commit('setQuery', params.query)
        commit('setSort', params.sort)
      }
    }
  }
})
