<template>
  <Loader
    :loading="loading"
    :class="[shippingOffers.length && 'with-shipping-msg', 'loader-container']"
  >
    <CartSummary
      :total-label="$t('layout.cart.totalPointLabel')"
      :total-text="totalText"
      :loyalty-point-label="$t('layout.cart.loyaltyPointLabel')"
      :loyalty-point-text="translatedLoyaltyPoints()"
    >
      <CartList slot="content">
        <CartListLineItem
          v-for="lineItem in lineItems"
          :id="lineItem.id"
          :key="lineItem.id"
          :type="lineItem.type"
          :img-src="lineItem.imgSrc"
          :brand-name="lineItem.brandName"
          :product-name="lineItem.productName"
          :unit-price="lineItem.unitPrice"
          :quantity-value="lineItem.quantityValue"
          :quantity-label="$t('layout.cart.quantityLabel')"
          :variant-name="variantName(lineItem.variantName)"
          :remove-button-label="$t('layout.cart.removeButtonLabel')"
          :free-text="$t('layout.cart.free')"
          @remove="$emit('remove-line-item', lineItem.id)"
        >
          <template #default="slotProps">
            <img v-lazy-load :data-src="slotProps.src" :alt="slotProps.alt" />
          </template>
        </CartListLineItem>
      </CartList>

      <CartShippingOffer
        v-if="shippingOffers.length"
        slot="content"
        :message="shippingOffers[0].message"
        :is-free-shipping="shippingOffers[0]['free-shipping']"
      />

      <template #view-cart-button>
        <div :class="showAtbTrigger ? 'atb-trigger' : 'view-bag-container'">
          <div v-if="showAtbTrigger" class="sign-in-container">
            <a id="dy-banner-atbd2-button-1" href="/sign_in">{{
              $t('layout.cart.signInCtaLabel')
            }}</a>

            <p>{{ $t('layout.cart.signInCtaMessage') }}</p>
          </div>

          <Button
            :id="showAtbTrigger && 'dy-banner-atbd1-button-3'"
            url="/cart"
            :label="$t('layout.cart.cartButtonText')"
            type="btn-primary"
            size="is-fullwidth"
          />
        </div>
      </template>
    </CartSummary>
  </Loader>
</template>
<script>
import CartSummary from '@sephora-asia/core-cart-summary/CartSummary.vue'
import CartList from '@sephora-asia/core-cart-list/CartList.vue'
import CartListLineItem from '@sephora-asia/core-cart-list-line-item/CartListLineItem.vue'
import Button from '@sephora-asia/core-button/Button.vue'
import Loader from '@sephora-asia/core-loader/Loader.vue'
import CartShippingOffer from './CartShippingOffer.vue'

export default {
  name: 'TopNavigationDropdownCartSummary',
  components: {
    Button,
    CartShippingOffer,
    CartSummary,
    CartList,
    CartListLineItem,
    Loader
  },
  props: {
    userLoggedIn: {
      type: Boolean,
      required: true
    },
    loyaltyPoints: {
      type: Number,
      default: 0
    },
    totalText: {
      type: String,
      required: true
    },
    lineItems: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    shippingOffers: {
      type: Array,
      default: () => []
    },
    showAtbTrigger: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    variantName(lineItemVariantName) {
      return lineItemVariantName.toUpperCase() === 'DEFAULT'
        ? ''
        : lineItemVariantName
    },
    translatedLoyaltyPoints() {
      return this.userLoggedIn === true
        ? this.$t('layout.cart.loyaltPoints', {}, { count: this.loyaltyPoints })
        : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.loader-container {
  /deep/ .content-card {
    height: 460px;
  }
  &.with-shipping-msg {
    /deep/ .footer {
      border: none;
    }
  }
}

.view-bag-container {
  width: 100%;
}

.atb-trigger {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1.1fr;
  align-items: center;
  gap: 12px;
  padding-top: 20px;
  border-top: 2px solid #f7f4f4;

  .sign-in-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    text-align: center;
    font-size: 16px;
    min-height: 72px;
    padding-right: 12px;
    border-right: 1px solid #ccc;

    a {
      text-decoration: underline;
    }
  }
}
</style>
