const DEVICE_ID_COOKIE_KEY = 'device-id'
const JWT_DATA_COOKIE_KEY = 'jwt-data'
const JWT_SIGNATURE_COOKIE_KEY = 'jwt-signature'
const JWT_REFRESH_TOKEN_COOKIE_KEY = 'jwt-refresh-token'
const JWT_TOKEN_COOKIE_KEY = 'jwt_token' // 'jwt_token' is used by luxola for old jwt token cookie name
const JWT_SCOPE_REQUIRED = 'user'

const JWT_COOKIE_OPTS = {
  maxAge: 60 * 60 * 24 * 60, // 60 days
  sameSite: 'Lax',
  secure: true,
  path: '/'
}

export {
  DEVICE_ID_COOKIE_KEY,
  JWT_DATA_COOKIE_KEY,
  JWT_SIGNATURE_COOKIE_KEY,
  JWT_REFRESH_TOKEN_COOKIE_KEY,
  JWT_TOKEN_COOKIE_KEY,
  JWT_SCOPE_REQUIRED,
  JWT_COOKIE_OPTS
}
