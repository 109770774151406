export default ({ app }) => {
  app.router.beforeEach((to, from, next) => {
    // i18n language setting
    const { languages = [], locale = '' } = app.i18n
    const nonDefaultLanguages = languages.slice(1)

    if (!to.query.language && nonDefaultLanguages.includes(locale)) {
      const newTo = { ...to }
      newTo.query = {
        language: locale,
        ...newTo.query
      }
      // next() with args restarts the navigation
      next(newTo)
    }

    next()
  })
}
