<template>
  <div class="sidebar-nav-slider">
    <transition name="slide-side">
      <SidebarNavMenu
        :categories="categories"
        @toggle-sidebar-nav="$emit('toggle-sidebar-nav')"
      />
    </transition>
    <div class="backdrop" @click="$emit('toggle-sidebar-nav')" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarNavMenu from '~/components/SidebarNavMenu.vue'
export default {
  name: 'SidebarNavSlider',

  components: {
    SidebarNavMenu
  },

  props: {
    showSidebarNav: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      categories: 'category/categories',
      hasOnlineReservationSystem: 'globalConfig/hasOnlineReservationSystem',
      isBookingServiceLoaded: 'bookingService/isBookingServiceLoaded'
    })
  },

  watch: {
    showSidebarNav(value, _) {
      if (
        value &&
        this.hasOnlineReservationSystem &&
        !this.isBookingServiceLoaded
      ) {
        this.$store.dispatch('bookingService/fetchAll')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.backdrop {
  bottom: 0;
  background: $grey-darker;
  height: 100%;
  left: 88%;
  opacity: 0.4;
  position: fixed;
  top: 0;
  width: 12%;
}
.sidebar-nav-slider {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: $zindex-modal;
}
.slide-side-enter-active,
.slide-side-leave-active {
  transition: all 0.3s ease-out;
}
.slide-side-enter,
.slide-side-leave-to {
  transform: translateX(-100%);
}
</style>
