export default class SearchItemsTransformer {
  constructor(items, options = {}) {
    this.items = items?.hits
    this.type = items?.index?.split('_')?.[0]
    this.language = options.language
  }

  get transformedData() {
    return this.type === 'variants'
      ? this.transformedVariantsData()
      : this.transformedItemsData()
  }

  transformedVariantsData() {
    return this.items.map((variant) => {
      return {
        id: variant.product.id,
        brandName:
          variant.product['brand-name'][this.language] ||
          variant.product['brand-name'].en,
        imgSrc: variant['closeup-image-url'],
        price: variant['display-price'],
        rating: variant.product.rating,
        slug: variant.product['slug-url'],
        name: variant.product.name[this.language] || variant.product.name.en,
        type: 'products'
      }
    })
  }

  // for categories and brands
  transformedItemsData() {
    return this.items.map((item) => {
      return {
        id: item.objectID,
        // this is to ensure attribute must be shown on UI if local language is set and data
        // available has only en(default) value
        name: item.name
          ? item.name[this.language] || item.name.en
          : item.label[this.language] || item.label.en,
        parentName: item.parent
          ? item.parent.label[this.language] || item.parent.label.en
          : null,
        slug: `/${this.type}/${item['slug-url']}`,
        type: this.type
      }
    })
  }
}
