import { mapGetters } from 'vuex'
import Vue from 'vue'
import categoriesDataMixin from '~/mixins/categoriesDataMixin'

const NEW_ARRIVALS_LINK = '/new-arrivals'
const GIFT_CARDS_LINK = '/gift-cards'
const CLEAN_AT_SEPHORA_LINK = '/pages/clean-at-sephora'

const NavBarCategoriesDataTransformer = (component) => {
  return Vue.component('NavBarCategoriesDataTransformer', {
    mixins: [categoriesDataMixin],

    props: {
      boundary: {
        type: String,
        default: ''
      },
      hasSingleCategoryRoot: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapGetters({
        supportGiftCard: 'globalConfig/supportGiftCard'
      }),

      categoryData() {
        const giftCard = this.categories.find(
          (category) => category.link === 'gifts'
        )

        const cleanCategory = this.categories.find(
          (category) => category.link === 'clean'
        )

        const formattedCategories = this.convertCategories(this.categories)

        // Add Clean At Sephora page to the tree
        if (cleanCategory) {
          formattedCategories[cleanCategory.id].children.push(
            this.noChildCategory(
              this.$t('layout.navBar.cleanAtSephora'),
              CLEAN_AT_SEPHORA_LINK
            )
          )
        }

        // Add E-Gift card to the tree
        if (this.supportGiftCard && giftCard) {
          formattedCategories[giftCard.id].children.push(
            this.noChildCategory(
              this.$t('layout.navBar.giftCards'),
              GIFT_CARDS_LINK
            )
          )
        }

        let categoryTree = this.arrangeCategories(
          this.categories,
          formattedCategories
        )

        if (this.hasSingleCategoryRoot) {
          const convertedCategoryFormat = this.convertCategoryFormat(
            this.$t('layout.navBar.category'),
            '',
            categoryTree
          )
          convertedCategoryFormat.children.map(
            (category) => (category.isL1Category = true)
          )
          categoryTree = [convertedCategoryFormat]
        }

        // Add new arrival data
        categoryTree.unshift(
          this.noChildCategory(this.$t('layout.navBar.new'), NEW_ARRIVALS_LINK)
        )

        return categoryTree
      }
    },

    render(createElement, _) {
      return createElement(component, {
        props: {
          categoryData: this.categoryData,
          boundary: this.boundary
        }
      })
    }
  })
}

export default NavBarCategoriesDataTransformer
