export default {
  props: {
    categories: {
      type: Array,
      required: true
    }
  },

  methods: {
    convertCategoryFormat(text, url, children = []) {
      return {
        text,
        url: `/categories/${url}`,
        children
      }
    },

    noChildCategory(text, url) {
      return {
        text,
        url,
        children: []
      }
    },

    convertCategories(categories, format = this.convertCategoryFormat) {
      const formattedCategories = {}
      categories.forEach((category) => {
        formattedCategories[category.id] = format(
          category.text,
          category.link,
          [],
          {
            additionalAttrs: this.additionalAttrs(category)
          }
        )
      })
      return formattedCategories
    },

    arrangeCategories(categories, formattedCategories) {
      const categoryTree = []
      categories.forEach((category, _) => {
        if (category.parentId) {
          // to eliminate the orphaned sub-category that causing error
          if (formattedCategories[category.parentId]) {
            formattedCategories[category.parentId].children.push(
              formattedCategories[category.id]
            )
          }
        } else {
          categoryTree.push(formattedCategories[category.id])
        }
      })
      return categoryTree
    },

    additionalAttrs(category, viewAll = false) {
      if (category.dataAnalytics) {
        const label = viewAll
          ? category.dataAnalytics.viewAllLabel
          : category.dataAnalytics.label
        return this.formattedAdditionalAttrs(label)
      }
      return {}
    },

    formattedAdditionalAttrs(label) {
      return {
        'data-analytics-id': 'side-menu',
        'data-analytics-label': label
      }
    }
  }
}
