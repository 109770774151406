<template>
  <div class="footer-social">
    <div class="title">
      {{ title }}
    </div>
    <div class="footer-social-links columns is-mobile">
      <div
        v-for="(link, name) in socialEntities(social)"
        :key="name"
        class="column is-narrow"
      >
        <SmartLink
          :native="false"
          :to="link.url"
          target="_blank"
          rel="noopener"
          :aria-label="name"
        >
          <Icons :name="name" :style="`--icon-size: 40px;`" />
        </SmartLink>
      </div>
    </div>
  </div>
</template>

<script>
import pick from 'lodash/pick'
import Icons from '@sephora-asia/atoms-icons/Icons.vue'

export default {
  name: 'FooterSocial',
  components: {
    Icons
  },

  props: {
    title: {
      type: String,
      required: true
    },
    social: {
      type: Object,
      required: true
    }
  },
  methods: {
    socialEntities(socialObject) {
      return pick(
        socialObject,
        'facebook',
        'twitter',
        'instagram',
        'youtube',
        'snapchat',
        'kakao',
        'line'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-social {
  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 5px;

    @include mobile {
      text-align: center;
      margin-top: 10px;
    }
  }

  @include mobile {
    .columns {
      justify-content: center;
    }
  }

  .social-icon {
    width: 35px;
    height: 35px;
  }
}

.footer-social-links {
  @include mobile {
    margin: 0;
  }
}
</style>
