<template functional>
  <div class="footer-copyright">
    <p>Copyright © {{ new Date().getFullYear() }} {{ props.entityName }}</p>
    <SmartLink :native="false" to="/terms" class="copyright-link">
      {{ props.termsOfUseLabel }}
    </SmartLink>
    |
    <SmartLink :native="false" to="/privacy" class="copyright-link">
      {{ props.privacyPolicyLabel }}
    </SmartLink>
  </div>
</template>

<script>
export default {
  name: 'FooterCopyright',
  props: {
    entityName: {
      type: String,
      default: ''
    },
    privacyPolicyLabel: {
      type: String,
      default: 'Privacy Policy'
    },
    termsOfUseLabel: {
      type: String,
      default: 'Terms of Use'
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-copyright {
  color: $grey-darker;
  font-size: 11px;
  line-height: 15px;
  padding-bottom: 30px;
  padding-top: 10px;
  text-align: center;

  .copyright-link {
    color: $grey-darker;
    padding: 3px;
    text-decoration: underline;
  }
}
</style>
