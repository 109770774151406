export const state = () => ({})

export const actions = {
  async nuxtServerInit({ commit }, context) {
    if (context.$config?.newRelicLicenseKey) {
      // used privateRuntimeConfig
      const newrelic = await import('newrelic')
      const cheerio = await import('cheerio')

      if (context.route.matched[0]) {
        newrelic.setTransactionName(context.route.matched[0].path.slice(1)) // use page router pattern as transaction name
      }

      // inject newrelic browser script
      const newrelicScript = newrelic.getBrowserTimingHeader()
      const script$ = cheerio.load(newrelicScript)
      context.app.head.script.push({
        hid: 'newrelic-browser',
        innerHTML: script$('script').html(), // don`t like this one
        type: 'text/javascript'
      })
    }

    const xAkamaiEdgescapeHeader = context.req?.headers?.['x-akamai-edgescape']
    if (xAkamaiEdgescapeHeader) {
      commit(
        'globalConfig/setUserOriginCountry',
        xAkamaiEdgescapeHeader
          ?.split(',')
          ?.find((pair) => pair.startsWith('country_code'))
          ?.slice(-2)
      )
    }

    if (context?.req?.headers?.['x-disable-af-banner']) {
      commit('globalConfig/setIsAppsflyerDisabled', true)
    }
  }
}
