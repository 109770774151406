<script>
export default {
  name: 'SmartLink',

  functional: true,

  props: {
    // Duplicated from https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L18
    // Add Object type to support nuxt-links with values of 'to' such as: { path: '/', query: { ref: 'logo' } }
    to: {
      type: [String, Object],
      required: true
    },
    native: {
      type: Boolean,
      default: true
    }
  },

  render(createElement, dataObject) {
    let element

    if (dataObject.props.native) {
      element = 'nuxt-link'
      dataObject.data.attrs.to = dataObject.props.to
    } else {
      element = 'a'
      dataObject.data.attrs.href = dataObject.props.to
    }

    return createElement(element, dataObject.data, dataObject.children)
  }
}
</script>
