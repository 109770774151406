import ShopByCampaignImagesTransformer from '../../api/ShopByCampaignImagesTransformer.js'

export default () => ({
  namespaced: true,

  state: () => ({
    title: '',
    images: []
  }),

  mutations: {
    setTitle(state, title) {
      state.title = title
    },
    setImages(state, images) {
      state.images = images
    }
  },

  getters: {
    title(state) {
      return state.title
    },
    images(state) {
      return state.images
    }
  },

  actions: {
    async fetchAll({ commit }) {
      const data = await this.$repositories.shopByCampaigns
        .index()
        .catch((e) => {})

      if (data.data) {
        commit('setTitle', data.data.attributes.title)
      }
      if (data.included) {
        commit(
          'setImages',
          new ShopByCampaignImagesTransformer(data.included).transformedData
        )
      }
    }
  }
})
