<template>
  <Notifications :max="1" width="100%">
    <template slot="body" slot-scope="props">
      <Notification
        :message="props.item.text"
        :type="props.item.type"
        name="toast"
        @close="props.close"
      />
    </template>
  </Notifications>
</template>

<script>
import Notification from '@sephora-asia/core-toast/Toast.vue'

export default {
  name: 'Toast',
  components: {
    Notification
  }
}
</script>

<style lang="scss">
/* override - https://github.com/euvl/vue-notification/blob/master/src/Notifications.vue#L383 */
.vue-notification-group {
  position: relative !important;
  z-index: inherit !important;
}
</style>
