<template>
  <div v-if="message" class="cart-shipping-offers">
    <Icons
      :name="`${isFreeShipping ? '' : 'no-'}free-shipping`"
      class="shipping-icon"
    />

    {{ message }}
  </div>
</template>

<script>
import Icons from '@sephora-asia/atoms-icons/Icons.vue'

export default {
  name: 'CartShippingOffer',
  components: {
    Icons
  },
  props: {
    message: {
      type: String,
      default: ''
    },
    isFreeShipping: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-shipping-offers {
  background-color: $color-brand-primary-lighter;
  padding: 8px 40px;
  font-size: $size-typography-size-s;
  font-weight: $size-typography-weight-book;
  line-height: $size-typography-line-height-m;
  display: flex;
  align-items: center;
  gap: 16px;
  min-height: 40px;

  .shipping-icon {
    --icon-size: 28px;

    /deep/ svg {
      margin: 0;
    }
  }
}
</style>
