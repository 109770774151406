export default class OverlayTransformer {
  constructor(overlay) {
    this.overlay = overlay
  }

  get transformedData() {
    return {
      id: this.overlay?.id,
      image: this.overlay?.attributes?.['image-url'],
      url: this.overlay?.attributes?.url,
      name: this.overlay?.attributes?.name
    }
  }
}
