import Vue from 'vue'
const SALE_LINK = '/sale'

const NavBarSaleDataTransformer = (component) => {
  return Vue.component('NavBarSaleDataTransformer', {
    props: {
      categories: {
        type: Array,
        required: true
      },
      boundary: {
        type: String,
        default: ''
      }
    },

    computed: {
      saleData() {
        const saleChildren = this.categories
          .filter((category) => category.parentId === null)
          .map((category) => {
            return {
              text: category.text,
              url: `${SALE_LINK}/${category.link}`,
              children: []
            }
          })

        return {
          text: this.$t('layout.navBar.sale'),
          url: SALE_LINK,
          children: saleChildren
        }
      }
    },

    render(createElement, _) {
      return createElement(component, {
        props: {
          saleData: this.saleData,
          boundary: this.boundary
        }
      })
    }
  })
}

export default NavBarSaleDataTransformer
