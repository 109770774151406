import get from 'lodash/get'

export default () => ({
  namespaced: true,

  state: () => ({
    layoutConfig: {},
    headerBannerConfig: {}
  }),

  mutations: {
    setLayoutConfig(state, layoutConfig) {
      state.layoutConfig = layoutConfig
    },
    setHeaderBannerConfig(state, headerBannerConfig) {
      state.headerBannerConfig = headerBannerConfig
    }
  },

  getters: {
    featuredContentHeader(state) {
      return get(state, 'layoutConfig.attributes.featured-content-header')
    },
    hasAndroidApp(state) {
      return get(state, 'layoutConfig.attributes.has-android-app')
    },
    hasIosApp(state) {
      return get(state, 'layoutConfig.attributes.has-ios-app')
    },
    headerBannerUrl(state) {
      return get(state, 'headerBannerConfig.url')
    },
    headerBannerImageUrl(state) {
      return get(state, 'headerBannerConfig.image-url')
    },
    headerBannerNewTab(state) {
      return get(state, 'headerBannerConfig.new-tab')
    },
    headerBannerValidTill(state) {
      return get(state, 'headerBannerConfig.valid-till')
    }
  },

  actions: {
    async fetchLayoutConfig({ commit }) {
      const { data } = await this.$repositories.layoutConfig.index()
      const layoutConfig = data
      if (layoutConfig) {
        const headerBannerConfig = layoutConfig.attributes['header-banner']
        delete layoutConfig.attributes['header-banner']
        commit('setLayoutConfig', layoutConfig)
        commit('setHeaderBannerConfig', headerBannerConfig)
      }
    }
  }
})
