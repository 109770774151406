import ProductTransformer from '../../api/ProductTransformer.js'

export default () => ({
  namespaced: true,

  state: () => ({
    carousels: [],
    products: []
  }),

  mutations: {
    setCarousels(state, carousels) {
      state.carousels = carousels
    },
    setProducts(state, products) {
      state.products = products
    }
  },

  getters: {
    carousels(state) {
      return state.carousels
    },
    products(state) {
      return state.products
    }
  },

  actions: {
    async fetchCarousels({ commit }) {
      const { data, included } = await this.$repositories.homePageCarousels
        .index({
          include: 'products.featured_variant'
        })
        .catch((e) => { })

      if (data) {
        commit('setCarousels', data)
      }

      if (included) {
        const products = included.filter((item) => item.type === 'products')
        const variants = included.filter((item) => item.type === 'variants')
        commit(
          'setProducts',
          new ProductTransformer(products, variants).transformedData
        )
      }
    }
  }
})
