<template>
  <FooterLayout>
    <template #about-us>
      <FooterList :title="aboutUsTitle" :list="aboutUsLinks" />
    </template>

    <template #mobile-about-us>
      <FooterListMobile :title="aboutUsTitle" :list="aboutUsLinks" />
    </template>

    <template #customer-care>
      <FooterList
        :title="$t('layout.footer.customerCare')"
        :list="customerCareLinks"
      />
      <FooterCustomerService />
    </template>

    <template #mobile-customer-care>
      <FooterListMobile
        :title="$t('layout.footer.customerCare')"
        :list="customerCareLinks"
      >
        <FooterCustomerService class="footer-customer-service-mobile" />
      </FooterListMobile>
    </template>

    <template #reward>
      <FooterRewards />
    </template>

    <template #connect-with-us>
      <FooterSocial :title="$t('layout.footer.connect')" :social="social" />
    </template>

    <template #get-app>
      <FooterGetApp v-if="showGetAppSection" />
    </template>

    <template #payment-options>
      <FooterPaymentOptions
        :payment-options="paymentOptions"
        :title="$t('layout.footer.paymentOptions')"
      />
    </template>

    <template #mobile-payment-options>
      <FooterPaymentOptions
        :payment-options="paymentOptions"
        :title="$t('layout.footer.paymentOptions')"
      />
    </template>
  </FooterLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import FooterLayout from '../layouts/FooterLayout.vue'
import FooterCustomerService from './FooterCustomerService'
import FooterGetApp from './FooterGetApp.vue'
import FooterList from './FooterList.vue'
import FooterListMobile from './FooterListMobile.vue'
import FooterPaymentOptions from './FooterPaymentOptions'
import FooterRewards from './FooterRewards.vue'
import FooterSocial from './FooterSocial.vue'

export default {
  name: 'FooterContainer',
  components: {
    FooterCustomerService,
    FooterGetApp,
    FooterLayout,
    FooterList,
    FooterListMobile,
    FooterPaymentOptions,
    FooterRewards,
    FooterSocial
  },
  computed: {
    ...mapGetters({
      aboutUsTitle: 'globalConfig/aboutUsTitle',
      aboutUsLinks: 'globalConfig/aboutUsLinks',
      customerCareLinks: 'globalConfig/customerCareLinks',
      organization: 'globalConfig/organization',
      paymentOptions: 'globalConfig/paymentOptions',
      showGetAppSection: 'globalConfig/showGetAppSection',
      social: 'globalConfig/social'
    })
  }
}
</script>

<style lang="scss" scoped>
.footer-customer-service-mobile {
  padding-left: 20px;
}
</style>
