export default class BillboardsTransformer {
  constructor(billboards) {
    this.billboards = billboards
  }

  get transformedData() {
    return this.billboards.map((billboard) => {
      return {
        id: billboard.id,
        url: billboard.attributes.link,
        imgSrc: billboard.attributes['image-url'] || '/',
        altText: billboard.attributes['alt-text'] || '',
        target: billboard.attributes['new-tab'] && '_blank',
        rel: billboard.attributes['new-tab'] && 'noopener',
        termsAndConditions: billboard.attributes['terms-and-conditions']
      }
    })
  }
}
