export default class ProductTransformer {
  constructor(products, variants) {
    this.products = products
    this.variants = variants
  }

  get transformedData() {
    return this.products.map((product) => {
      const featuredVariant = this.variants.find(
        (variant) => variant.id == product.attributes['featured-variant-id']
      )

      return {
        id: product.id,
        brandName: product.attributes['brand-name'],
        featuredVariantId: product.attributes['featured-variant-id'],
        isNew: product.attributes['new-arrival'],
        productName: product.attributes.name,
        rating: product.attributes.rating,
        reviewsCount: product.attributes['reviews-count'],
        slugUrl: product.attributes['slug-url'],
        variantsCount: product.attributes['variants-count'],
        featuredVariant: {
          id: featuredVariant.id,
          imageUrl: featuredVariant.attributes['default-image-urls'][0],
          iconUrl: featuredVariant.attributes['icon-url'],
          inventory: featuredVariant.attributes.inventory,
          onSale: featuredVariant.attributes['under-sale'],
          originalPrice: featuredVariant.attributes['display-original-price'],
          price: featuredVariant.attributes['display-price'],
          saleText: featuredVariant.attributes['sale-text'],
          slugUrl: featuredVariant.attributes['slug-url'],
          name: featuredVariant.attributes.name,
          soldOut: featuredVariant.attributes['sold-out'],
          seal: featuredVariant.attributes.seal,
          tags: featuredVariant.attributes.tags
        }
      }
    })
  }
}
