<template>
  <div
    v-if="customerService.displayInFooter || false"
    class="footer-customer-service"
  >
    <p v-if="customerService.emailAddress">
      {{ customerService.emailAddress }}
    </p>
    <p v-if="customerService.phoneNumber">
      {{ customerService.phoneNumber }}
    </p>
    <p v-if="customerService.alternatePhoneNumber">
      {{ customerService.alternatePhoneNumber }}
    </p>
    <p v-if="customerService.operatingHours">
      {{ customerService.operatingHours }}
    </p>
    <p v-if="customerService.streetAddress">
      {{ customerService.streetAddress }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FooterCustomerService',
  computed: {
    ...mapGetters({
      customerService: 'globalConfig/customerService'
    })
  }
}
</script>

<style lang="scss" scoped>
.footer-customer-service {
  color: $grey-darker;
}
</style>
