<template>
  <div class="section is-paddingless">
    <div
      class="container has-page-padding is-hidden-mobile main-footer-section"
    >
      <div class="columns is-multiline">
        <div class="column is-2-desktop is-3-tablet">
          <slot name="about-us" />
        </div>
        <div class="column is-2-desktop is-3-tablet">
          <slot name="customer-care" />
        </div>
        <div
          class="column"
          :class="
            showGetAppSection
              ? ['is-2-desktop', 'is-3-tablet']
              : ['is-4-desktop', 'is-6-tablet']
          "
        >
          <slot name="reward" />
        </div>
        <div v-if="showGetAppSection" class="column is-2-desktop is-3-tablet">
          <slot name="get-app" />
        </div>
        <div class="column is-4-desktop is-6-tablet">
          <slot name="connect-with-us" />
          <div class="is-hidden-tablet-only">
            <slot name="payment-options" />
          </div>
        </div>
        <div class="column is-hidden-desktop is-6-tablet">
          <slot name="payment-options" />
        </div>
      </div>
    </div>

    <div class="container is-hidden-tablet">
      <div class="accordion-container">
        <slot name="mobile-about-us" />
        <slot name="mobile-customer-care" />
      </div>
      <div class="columns is-mobile is-marginless">
        <div class="column">
          <slot name="reward" />
        </div>
        <div v-if="showGetAppSection" class="column">
          <slot name="get-app" />
        </div>
      </div>
      <slot name="connect-with-us" />
      <slot name="mobile-payment-options" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FooterLayout',
  computed: {
    ...mapGetters({
      showGetAppSection: 'globalConfig/showGetAppSection'
    })
  }
}
</script>

<style>
.accordion-container {
  margin-bottom: 30px;
}

.main-footer-section {
  margin-top: 24px;
  margin-bottom: 24px;
}
</style>
