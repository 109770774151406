export default class BrandsTransformer {
  constructor(brands) {
    this.brands = brands
  }

  get transformedData() {
    return this.brands.map((brand) => {
      return {
        label: brand.attributes.name,
        link: `/brands/${brand.attributes['slug-url']}`
      }
    })
  }
}
