<template>
  <div class="sephora-logo-container">
    <SmartLink
      :to="{ path: '/', query: { ref: 'logo' } }"
      aria-label="Sephora Homepage"
    >
      <SephoraLogo
        :class="$i18n.countryCode.toLowerCase()"
        class="logo"
        alt="Sephora"
      />
    </SmartLink>
  </div>
</template>

<script>
import SephoraLogo from '~/assets/images/sephora-logo.svg?inline'

export default {
  name: 'SephoraLogoContainer',
  components: {
    SephoraLogo
  }
}
</script>

<style lang="scss" scoped>
.sephora-logo-container {
  .logo {
    padding-top: 5px;
    height: 32px;
  }
}
</style>
