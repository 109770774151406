export default ({ app }) => {
  app.router.afterEach((to, from) => {
    // ensure changing route or first load to send event
    if (app.$analytics) {
      if (to.path !== from.path || from.name === null) {
        const pageUrl = window.config
          ? window.config.domain.replace(/\/$/, '') + to.fullPath
          : window.location.origin + to.fullPath
        app.$analytics.trackEvent('page_viewed', { pageUrl })
      }
    }
  })
}
