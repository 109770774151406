const AvailableLanguages = {
  AU: ['en'],
  HK: ['en', 'zh'], // First element is the country's default language code
  ID: ['id', 'en'],
  KR: ['ko'],
  MY: ['en'],
  NZ: ['en'],
  PH: ['en'],
  SG: ['en'],
  TH: ['th', 'en']
}

const CountryCodes = Object.keys(AvailableLanguages)

const COUNTRY_CODE_COOKIE_KEY = 'locale'

const LANGUAGE_CODE_COOKIE_KEY = 'language'

const COOKIE_MAX_AGE = 60 * 60 * 24 * 365 // 1 year

const DEFAULT_COUNTRY_CODE = 'SG'

const X_SITE_COUNTRY_HEADER_KEY = 'x-site-country'

export {
  AvailableLanguages,
  COOKIE_MAX_AGE,
  COUNTRY_CODE_COOKIE_KEY,
  CountryCodes,
  DEFAULT_COUNTRY_CODE,
  LANGUAGE_CODE_COOKIE_KEY,
  X_SITE_COUNTRY_HEADER_KEY
}
