export default class QuickLinksTransformer {
  constructor(quickLinks) {
    this.quickLinks = quickLinks
  }

  get transformedData() {
    return this.quickLinks.map((quickLink) => ({
      id: quickLink.id,
      url: quickLink.attributes.link,
      text: quickLink.attributes.name,
      openInNewTab: quickLink.attributes['new-tab']
    }))
  }
}
