import BillboardsTransformer from '../../api/BillboardsTransformer.js'

export default () => ({
  namespaced: true,

  state: () => ({
    billboards: [],
    contentBanners: [],
    featuredContent: []
  }),

  mutations: {
    setBillboards(state, billboards) {
      state.billboards = billboards
    },
    setContentBanners(state, contentBanners) {
      state.contentBanners = contentBanners
    },
    setFeaturedContent(state, featuredContent) {
      state.featuredContent = featuredContent
    }
  },

  getters: {
    billboards(state) {
      return state.billboards
    },
    contentBanners(state) {
      return state.contentBanners
    },
    featuredContent(state) {
      return state.featuredContent
    }
  },

  actions: {
    async fetchAll({ commit }) {
      // billboard platform in luxola is defined as enum, hence using the initial value mobile and main
      const platform = this.$device.isMobile ? 'mobile' : 'main'
      const { data, included } = await this.$repositories.billboards.index({
        'filter[platform]': platform,
        include: 'content_banners,featured_content'
      })

      commit('setBillboards', new BillboardsTransformer(data).transformedData)

      if (included.content_banners) {
        commit(
          'setContentBanners',
          new BillboardsTransformer(included.content_banners).transformedData
        )
      }

      if (included.featured_content) {
        commit(
          'setFeaturedContent',
          new BillboardsTransformer(included.featured_content).transformedData
        )
      }
    }
  }
})
