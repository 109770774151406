import { render, staticRenderFns } from "./CartShippingOffer.vue?vue&type=template&id=52f84490&scoped=true&"
import script from "./CartShippingOffer.vue?vue&type=script&lang=js&"
export * from "./CartShippingOffer.vue?vue&type=script&lang=js&"
import style0 from "./CartShippingOffer.vue?vue&type=style&index=0&id=52f84490&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52f84490",
  null
  
)

export default component.exports