<template>
  <PromotionBanner :promotions="promotions" />
</template>

<script>
import { mapGetters } from 'vuex'
import PromotionBanner from '@sephora-asia/core-promotion-banner/PromotionBanner.vue'

export default {
  name: 'PromotionBannerContainer',

  components: {
    PromotionBanner
  },

  computed: {
    ...mapGetters({
      promotions: 'globalConfig/promotions'
    })
  }
}
</script>
