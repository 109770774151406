import cart from './modules/cart'
import user from './modules/user'
import waitlist from './modules/waitlist'
import wishlist from './modules/wishlist'
import category from './modules/category'
// get the options out using lodash templates
const options = JSON.parse(`{"modules":["analytics","i18n"],"namespace":"commonUI"}`)

const modules = { cart, user, waitlist, wishlist, category }

export default ({ store }, inject) => {
  for (const moduleName in modules) {
    store.registerModule(moduleName, modules[moduleName](options), {
      preserveState: Boolean(store.state[moduleName]) // if the store module already exists, preserve it
    })
  }
}
