import LineItemTransformer from '../../api/LineItemTransformer.js'
import CartTransformer from '../../api/CartTransformer.js'

export default () => ({
  namespaced: true,
  state: () => ({
    cart: {}
  }),

  mutations: {
    setCart(state, cart) {
      state.cart = cart
    },
    setLineItems(state, lineItems) {
      state.cart.lineItems = lineItems
      state.cart.lineItemsCount = lineItems.length
    },
    setLineItemsCount(state, count) {
      state.cart.lineItemsCount = count
    }
  },

  getters: {
    total(state) {
      return state.cart.displayTotal
    },
    subtotal(state) {
      return state.cart.displaySubtotal
    },
    lineItems(state) {
      if (state.cart && state.cart.lineItems) {
        return state.cart.lineItems
      } else {
        return []
      }
    },
    lineItemsCount(state) {
      return (state.cart && state.cart.lineItemsCount) || 0
    },
    shippingOffers(state) {
      return state.cart && state.cart.shippingOffers
    }
  },

  actions: {
    async fetchLineItemsCount({ commit }) {
      const cartToken = this.$cookies.get('cart-token') || ''
      if (!cartToken) return

      try {
        const { data } = await this.$repositories.bag.index({
          id: `${cartToken}/items_count`
        })

        if (cartToken !== data.attributes.token) {
          this.$cookies.set('cart-token', data.attributes.token)
        }

        commit('setLineItemsCount', data.attributes['line-items-count'])
      } catch (error) {
        console.error('Line Items Count API', error)
      }
    },

    async fetch({ commit, state }) {
      const cartToken = this.$cookies.get('cart-token') || ''

      if (!cartToken || (state.cart.token && state.cart.token === cartToken))
        return

      const { data } = await this.$repositories.bag.index({
        id: `${cartToken}/bag`
      })

      if (cartToken !== data.attributes.token) {
        this.$cookies.set('cart-token', data.attributes.token)
      }

      this.dispatch('cart/update', data)
    },

    async deleteLineItem({ commit }, id) {
      const cartToken = this.$cookies.get('cart-token')

      if (cartToken) {
        const { data, included } = await this.$repositories.lineItems.destroy(
          {
            id,
            include: 'line_items.product.variants,line_items.sample.variants'
          },
          { 'X-CART-TOKEN': cartToken }
        )

        this.dispatch('cart/update', data)
        this.dispatch('cart/updateLineItems', included)
      } else {
        throw new Error('Cart token not found')
      }
    },

    update({ commit }, data) {
      commit('setCart', new CartTransformer(data).transformedData)
    },

    updateLineItems({ commit }, included) {
      if (included) {
        const lineItems = included.filter((item) => item.type === 'line-items')
        const variants = included.filter((item) => item.type === 'variants')

        commit(
          'setLineItems',
          new LineItemTransformer(lineItems, variants).transformedData
        )
      } else {
        commit('setLineItems', [])
      }
    },

    async createLineItem({ state }, id, quantity = 1) {
      const cartToken = this.$cookies.get('cart-token')

      const { data, included } = await this.$repositories.lineItems.post(
        {
          line_items: {
            variant_id: id,
            quantity
          }
        },
        {
          include: 'line_items.product.variants,line_items.sample.variants'
        },
        { 'X-CART-TOKEN': cartToken }
      )

      if (cartToken !== data.attributes.token) {
        this.$cookies.set('cart-token', data.attributes.token)
      }

      this.dispatch('cart/update', data)
      this.dispatch('cart/updateLineItems', included)
    }
  }
})
