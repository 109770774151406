import BrandsRecommendationsTransformer from '../../api/BrandRecommendationsTransformer'

export default () => ({
  namespaced: true,

  state: () => ({
    recommendations: []
  }),

  mutations: {
    setListingPagesBrandRecommendations(state, recommendations) {
      state.recommendations = recommendations
    }
  },

  getters: {
    recommendations(state) {
      return state.recommendations || []
    }
  },

  actions: {
    async fetchBrandRecommendations(
      { commit },
      { path, recommendationsCount }
    ) {
      try {
        commit('setListingPagesBrandRecommendations', [])

        const {
          recommendations: { data, included }
        } = await this.$repositories.brandRecommendations.post({
          page_path: path,
          n_recommendations: recommendationsCount
        })

        if (data?.length) {
          commit(
            'setListingPagesBrandRecommendations',
            new BrandsRecommendationsTransformer(data, included).transformedData
          )
        }
      } catch {}
    }
  }
})
