<template>
  <div class="footer-rewards">
    <ContentCard>
      <div class="is-uppercase title">
        {{ $t('layout.footer.beautyPass') }}
      </div>
      <img
        v-lazy-load
        class="beauty-pass-image"
        :data-src="$options.locals.beautyPassLogo"
        :alt="$t('layout.footer.beautyPass')"
      />
      <Button
        :label="$t('layout.footer.exploreBenefits')"
        url="/beauty-pass"
        type="btn-white"
        size="is-fullwidth"
        class="explore-benefits-button"
      />
    </ContentCard>
  </div>
</template>

<script>
import Button from '@sephora-asia/core-button/Button.vue'
import ContentCard from '@sephora-asia/core-content-card/ContentCard.vue'
import beautyPassLogo from '~/assets/images/beauty-pass-logo.png'

export default {
  name: 'FooterRewards',

  components: {
    Button,
    ContentCard
  },

  locals: {
    beautyPassLogo
  }
}
</script>

<style lang="scss" scoped>
/deep/ .content-card {
  justify-content: flex-start;
}

.footer-rewards {
  max-width: 205px;
  margin: 0 auto;
}

.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.beauty-pass-image {
  padding-top: 20px;
  padding-bottom: 16px;
}

.explore-benefits-button {
  font-size: 14px;
}
</style>
