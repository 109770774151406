import AdTransformer from './AdTransformer.js'

export default class ProductsTransformer {
  constructor(products, included, meta) {
    this.brands = included?.filter((record) => record.type === 'brands')
    this.ads = included?.filter((record) => record.type === 'ads')
    this.products = products
    this.variants = included?.filter((record) => record.type === 'variants')
    this.meta = meta
  }

  get transformedData() {
    return this.products.map((product) => {
      const brand = this.brands.find(
        (brand) => brand.id === product.relationships.brand.data.id
      )
      const featuredAd = this.ads.find(
        (ad) => ad.id === product.relationships['featured-ad']?.data?.id
      )
      const featuredVariant = this.variants.find(
        (variant) => variant.id == product.attributes['featured-variant-id']
      )

      return {
        id: product.id,
        ad: new AdTransformer(featuredAd)?.transformedData,
        brandName: brand.attributes.name || '',
        featuredVariantId: product.attributes['featured-variant-id'],
        isNew: product.attributes['new-arrival'],
        optionType: product.attributes['option-type-categories'][0] || 'type',
        pdpUrl: `/products/${product.attributes['slug-url']}`,
        productName: product.attributes.name,
        rating: product.attributes.rating,
        reviewsCount: product.attributes['reviews-count'],
        soldOut: product.attributes['sold-out'],
        variantsCount: product.attributes['variants-count'],
        launchStatus: product.attributes['launch-status'],
        launchConfig: product.attributes['launch-config'],
        featuredVariant: {
          id: featuredVariant.id,
          slugUrl: featuredVariant.attributes['slug-url'],
          name: featuredVariant.attributes.name,
          originalPrice: featuredVariant.attributes['display-original-price'],
          price: featuredVariant.attributes['display-price'],
          saleText: featuredVariant.attributes['sale-text'],
          onSale: featuredVariant.attributes['under-sale'],
          imageUrl: featuredVariant.attributes['image-urls'][0],
          sapSku: featuredVariant.attributes['sap-sku'],
          soldOut: featuredVariant.attributes['sold-out'],
          seal: featuredVariant.attributes.seal,
          tags: featuredVariant.attributes.tags,
          launchStatus: featuredVariant.attributes['launch-status'],
          launchConfig: featuredVariant.attributes['launch-config']
        }
      }
    })
  }

  get transformedMeta() {
    return {
      currentPage: this.meta['current-page'],
      perPage: this.meta['per-page'],
      totalPages: this.meta['total-pages'],
      totalProducts: this.meta['total-items']
    }
  }
}
