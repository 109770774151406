import camelCase from 'lodash/camelCase'
import kebabCase from 'lodash/kebabCase'

export default class FiltersTransformer {
  constructor(data, included) {
    this.data = data
    this.brands = included.filter(
      (record) =>
        record.type === 'brands' &&
        this.data.relationships.brands.data
          .map((value) => value.id)
          .includes(record.id)
    )
    this.categories = included.filter(
      (record) =>
        record.type === 'categories' &&
        this.data.relationships.categories.data
          .map((value) => value.id)
          .includes(record.id)
    )
    this.filterTypes = included.filter(
      (record) =>
        record.type === 'filter-types' &&
        this.data.relationships['filter-types'].data
          .map((value) => value.id)
          .includes(record.id)
    )
    this.filterValues = included.filter(
      (record) => record.type === 'filter-values'
    )
    this.otherFilters = data.attributes['other-filters']
  }

  transformedBrandFiltersData() {
    return this.brands.map((brand) => ({
      id: brand.id,
      name: brand.attributes.name,
      slugUrl: brand.attributes['slug-url']
    }))
  }

  transformedCategoryFiltersData() {
    return this.categories.map((category) => ({
      id: category.id?.toString(),
      label: category.attributes.label,
      parentId: category.attributes['parent-id']?.toString(),
      slugUrl: category.attributes['slug-url']
    }))
  }

  transformedOtherFiltersData() {
    return this.otherFilters.map((otherFilter) => ({
      name: camelCase(otherFilter),
      slugUrl: otherFilter
    }))
  }

  transformedPriceRangeFilterData() {
    return {
      minPrice: this.data.attributes['min-price'],
      maxPrice: this.data.attributes['max-price']
    }
  }

  transformedTypeFiltersData() {
    return this.filterTypes.map((filterType) => ({
      id: filterType.id,
      name: filterType.attributes.name,
      slugUrl: kebabCase(filterType.attributes.name),
      filterValues: this.filterValues
        .filter((filterValue) =>
          filterType.relationships['filter-values'].data
            .map((value) => value.id)
            .includes(filterValue.id)
        )
        .map((filterValue) => ({
          id: filterValue.id,
          name: filterValue.attributes.value,
          slugUrl: kebabCase(filterValue.attributes.value)
        }))
    }))
  }
}
