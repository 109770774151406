export default {
  methods: {
    convertToMenuItemFormat(text, url, options) {
      const defaultOptions = {
        children: [],
        description: null,
        hasSeparator: true,
        isNonNativeLink: false,
        urlPrefix: ''
      }

      options = { ...defaultOptions, ...options }

      return {
        text,
        description: options.description,
        url: `${options.urlPrefix}${url}`,
        children: options.children,
        iconSrc: options.iconSrc,
        isNonNativeLink: options.isNonNativeLink,
        hasSeparator: options.hasSeparator,
        additionalAttrs: options.additionalAttrs
      }
    }
  }
}
