export default () => ({
  namespaced: true,

  state: () => ({
    waitlistedVariantIds: []
  }),

  mutations: {
    setWaitlistedVariantIds(state, waitlistedVariantIds) {
      state.waitlistedVariantIds = waitlistedVariantIds
    }
  },

  getters: {
    waitlistedVariantIds(state) {
      return state.waitlistedVariantIds
    }
  },

  actions: {
    async fetchWaitlist({ commit, state, rootGetters }) {
      // This getter is referenced from common-ui user store
      // might need to refactor for microfrontend structure as it's tightly coupled atm.
      if (!rootGetters['user/loggedIn']) return
      if (state.waitlistedVariantIds.length !== 0) return
      let waitlistedVariantIds

      // Check localStorage
      try {
        waitlistedVariantIds =
          JSON.parse(globalThis.localStorage?.getItem('waitlistedVariants')) ||
          []
      } catch {
        waitlistedVariantIds = []
      }

      if (waitlistedVariantIds.length !== 0) {
        commit('setWaitlistedVariantIds', waitlistedVariantIds)
        return
      }

      // Call API
      const { data } =
        (await this.$repositories.waitlists
          .index({
            'page[size]': 500
          })
          .catch((e) => {})) || {}

      if (!data) return

      waitlistedVariantIds = data.map((variant) => Number(variant.id))

      globalThis.localStorage?.setItem(
        'waitlistedVariants',
        JSON.stringify(waitlistedVariantIds)
      )

      commit('setWaitlistedVariantIds', waitlistedVariantIds)
    },

    async addToWaitlist({ commit, rootGetters }, id) {
      // This getter is referenced from common-ui user store
      // might need to refactor for microfrontend structure as it's tightly coupled atm.
      if (!rootGetters['user/loggedIn']) return

      const { data } =
        (await this.$repositories.waitlistVariants
          .put({ id })
          .catch((e) => {})) || {}

      if (!data) return

      const waitlistedVariantIds = data.relationships.variants.data.map(
        (variant) => Number(variant.id)
      )

      globalThis.localStorage?.setItem(
        'waitlistedVariants',
        JSON.stringify(waitlistedVariantIds)
      )

      commit('setWaitlistedVariantIds', waitlistedVariantIds)
    },

    clearWaitlist({ commit }) {
      globalThis.localStorage?.removeItem('waitlistedVariants')
      commit('setWaitlistedVariantIds', [])
    }
  }
})
