import BrandsTransformer from '../api/BrandsTransformer.js'

export const state = () => ({
  brands: []
})

export const mutations = {
  setBrands(state, brands) {
    state.brands = brands
  }
}

export const getters = {
  brands(state) {
    return state.brands
  }
}

export const actions = {
  async fetchAll({ commit }) {
    const { data } = await this.$repositories.brands.index({
      'page[size]': 500,
      'page[number]': 1
    })
    if (data) {
      commit('setBrands', new BrandsTransformer(data).transformedData)
    }
  }
}
