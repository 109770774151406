<template>
  <SmartLink
    :native="false"
    :to="link"
    :target="hrefTarget"
    class="ga-tracking-sitewide-banner"
  >
    <nuxt-img loading="lazy" :src="imgSrc" alt="Sitewide Banner" />
  </SmartLink>
</template>

<script>
export default {
  name: 'HeaderBanner',
  props: {
    imgSrc: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    openInNewTab: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hrefTarget() {
      return this.openInNewTab ? '_blank' : null
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
</style>
